import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import * as authActions from './auth.actions';
import { AuthState } from './auth-state.interface';
import Jwt from './interfaces/jwt.interface';
import CurrentUser from './interfaces/current-user.interface';
import TokenTypes from './enums/token-types.enum';

export const initialState: AuthState = {
    currentUser: null,
    error: [],
    jwt: null,
    loading: false,
    loaded: false,
    logoutMessage: null,
    isLoggedOut: null,
};

const authReducer: ActionReducer<AuthState, Action> = createReducer(
    initialState,
    on(authActions.loginUserRequest, (state: AuthState) => ({
        ...state,
        loading: true,
        loaded: false,
    })),
    on(
        authActions.loginUserSuccess,
        (state: AuthState, { jwt, currentUser }: { jwt: Jwt; currentUser: CurrentUser }) => ({
            ...state,
            jwt,
            currentUser,
            loading: false,
            loaded: true,
            logoutMessage: null as string,
            isLoggedOut: false,
        }),
    ),
    on(
        authActions.loginUserFailure,
        (state: AuthState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            error: [...state.error, errorResponse.error],
            loading: false,
            loaded: false,
            logoutMessage: null as string,
            isLoggedOut: null as boolean,
        }),
    ),
    on(authActions.logoutUserSuccess, (state: AuthState, { message }: { message: string }) => ({
        ...state,
        jwt: null as Jwt,
        logoutMessage: message,
        isLoggedOut: true,
    })),
    on(authActions.logoutUserFailure, (state: AuthState, { message }: { message: string }) => ({
        ...state,
        logoutMessage: message,
        isLoggedOut: false,
    })),
    on(
        authActions.rehydrateTokenSuccess,
        (state: AuthState, { key, token }: { key: TokenTypes; token: string }) => ({
            ...state,
            jwt: {
                ...state.jwt,
                [key]: token,
            },
        }),
    ),
    on(authActions.rehydrateTokenFailure, (state: AuthState, { key }: { key: TokenTypes }) => ({
        ...state,
        jwt: {
            ...state.jwt,
            [key]: null as string,
        },
    })),
    on(
        authActions.getCurrentUserSuccess,
        (state: AuthState, { currentUser }: { currentUser: CurrentUser }) => ({
            ...state,
            currentUser,
        }),
    ),
    on(authActions.getCurrentUserFailure, (state: AuthState) => ({
        ...state,
        currentUser: null as CurrentUser,
    })),
    on(authActions.refreshLoginRequest, (state: AuthState) => ({
        ...state,
    })),
    on(authActions.refreshLoginSuccess, (state: AuthState, { jwt }: { jwt: Jwt }) => ({
        ...state,
        jwt,
    })),
    on(
        authActions.refreshLoginFailure,
        (state: AuthState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            error: [...state.error, errorResponse.error],
        }),
    ),
    on(authActions.acceptTermsOfUseRequest, (state: AuthState) => ({
        ...state,
    })),
    on(authActions.acceptTermsOfUseSuccess, (state: AuthState, { jwt }: { jwt: Jwt }) => ({
        ...state,
        jwt,
    })),
    on(
        authActions.acceptTermsOfUseFailure,
        (state: AuthState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            error: [...state.error, errorResponse.error],
        }),
    ),
    on(authActions.clearNextError, (state: AuthState) => ({
        ...state,
        error: state.error.slice(1),
    })),
);

export const reducer = (state: AuthState | undefined, action: Action): AuthState =>
    authReducer(state, action);

export const getAuthCurrentUser: (state: AuthState) => CurrentUser = (state: AuthState) =>
    state.currentUser;
export const getAuthError: (state: AuthState) => ErrorResponse[] = (state: AuthState) =>
    state.error;
export const getAuthJwt: (state: AuthState) => Jwt = (state: AuthState) => state.jwt;
export const getAuthLoading: (state: AuthState) => boolean = (state: AuthState) => state.loading;
export const getAuthLoaded: (state: AuthState) => boolean = (state: AuthState) => state.loaded;
