import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { CurrentUserParser } from '../parsers/current-user.parser';
import CurrentUser from '../interfaces/current-user.interface';
import CurrentUserIdentityRestApi from '../rest-api-interfaces/current-user-identity.rest.interface';
import TokenTypes from '../enums/token-types.enum';

@Injectable()
export class CurrentUserService {
    constructor(private tokenService: TokenService, private currentUserParser: CurrentUserParser) {}

    /**
     * Returns the CurrentUer identity by decoding the accessToken located in the localStorage.
     * If the token does not exist it returns null.
     */
    getUserIdentity(): CurrentUser | null {
        const token: string = this.tokenService.getToken(TokenTypes.access);

        // if the token exists find user's identity located in payload
        if (token) {
            const jwtPayload: string = token.split('.')[1];
            const base64: string = jwtPayload.replace('-', '+').replace('_', '/');
            const identity: CurrentUserIdentityRestApi = JSON.parse(window.atob(base64)).identity;
            return this.currentUserParser.parseCurrentUser(identity);
        }

        return null;
    }
}
