import { Injectable } from '@angular/core';
import TokenTypes from '../enums/token-types.enum';

@Injectable()
export class TokenService {
    constructor() {}

    /** Gets token from localStorage */
    getToken(tokenKey: TokenTypes): string {
        const token: string = localStorage.getItem(tokenKey as string);
        return token ? token : null;
    }

    /** Sets a token to localStorage */
    setToken(tokenKey: TokenTypes, tokenValue: string): void {
        localStorage.setItem(tokenKey as string, tokenValue);
    }

    /** Removes token from localStorage */
    removeToken(tokenKey: TokenTypes): void {
        const token: string = this.getToken(tokenKey);
        if (token) {
            localStorage.removeItem(tokenKey as string);
        }
    }
}
