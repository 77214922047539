/** third-party imports */
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import { CompoundReportActionTypes } from './compound-report-action-types.enum';
import Overview from './interfaces/overview.interface';
import RelationshipGroups from './interfaces/relationship-groups.interface';
import RelationshipGroup from '../ingredient-profiler/enums/relationship-group.enum';
import Effect from '../ingredient-profiler/enums/effect.enum';
import EffectSummaries from './interfaces/effect-summaries.interface';
import HealthLabel from './interfaces/health-label.interface';
import StudyTypes from './interfaces/study-types.interface';
import StudyType from './interfaces/study-type.interface';
import RelationshipGroupSummaries from './interfaces/relationship-group-summaries.interface';
import Summary from '../report/interfaces/summary.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';

export const getOverviewRequest = createAction(
    CompoundReportActionTypes.GET_OVERVIEW_REQUEST,
    props<{
        compoundId: string;
        ingredientName: string;
        filters: ExecutionFilters;
        areEffectsEnabled: boolean;
    }>(),
);

export const getOverviewSuccess = createAction(
    CompoundReportActionTypes.GET_OVERVIEW_SUCCESS,
    props<{ overview: Overview; total: number }>(),
);

export const getOverviewFailure = createAction(
    CompoundReportActionTypes.GET_OVERVIEW_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getRelationshipGroupsRequest = createAction(
    CompoundReportActionTypes.GET_RELATIONSHIP_GROUPS_REQUEST,
    props<{ compoundId: string; ingredientName: string; filters: ExecutionFilters }>(),
);

export const getRelationshipGroupsSuccess = createAction(
    CompoundReportActionTypes.GET_RELATIONSHIP_GROUPS_SUCCESS,
    props<{ relationshipGroups: RelationshipGroups }>(),
);

export const getRelationshipGroupsFailure = createAction(
    CompoundReportActionTypes.GET_RELATIONSHIP_GROUPS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getRelationshipGroupSummariesRequest = createAction(
    CompoundReportActionTypes.GET_RELATIONSHIP_GROUP_SUMMARIES_REQUEST,
    props<{
        compoundId: string;
        ingredientName: string;
        relationshipGroup: RelationshipGroup;
        filters: ExecutionFilters;
    }>(),
);

export const getRelationshipGroupSummariesSuccess = createAction(
    CompoundReportActionTypes.GET_RELATIONSHIP_GROUP_SUMMARIES_SUCCESS,
    props<{ relationshipGroupSummaries: RelationshipGroupSummaries }>(),
);

export const getRelationshipGroupSummariesFailure = createAction(
    CompoundReportActionTypes.GET_RELATIONSHIP_GROUP_SUMMARIES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getEffectSummariesRequest = createAction(
    CompoundReportActionTypes.GET_EFFECT_SUMMARIES_REQUEST,
    props<{
        compoundId: string;
        ingredientName: string;
        effect: Effect;
        filters: ExecutionFilters;
    }>(),
);

export const getEffectSummariesSuccess = createAction(
    CompoundReportActionTypes.GET_EFFECT_SUMMARIES_SUCCESS,
    props<{ effectSummaries: EffectSummaries }>(),
);

export const getEffectSummariesFailure = createAction(
    CompoundReportActionTypes.GET_EFFECT_SUMMARIES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getHealthLabelsRequest = createAction(
    CompoundReportActionTypes.GET_HEALTH_LABELS_REQUEST,
    props<{
        compoundId: string;
        ingredientName: string;
        filters: ExecutionFilters;
        areEffectsEnabled: boolean;
    }>(),
);

export const getHealthLabelsSuccess = createAction(
    CompoundReportActionTypes.GET_HEALTH_LABELS_SUCCESS,
    props<{ healthLabels: HealthLabel[] }>(),
);

export const getHealthLabelsFailure = createAction(
    CompoundReportActionTypes.GET_HEALTH_LABELS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getHealthLabelSummaryRequest = createAction(
    CompoundReportActionTypes.GET_HEALTH_LABEL_SUMMARY_REQUEST,
    props<{
        compoundId: string;
        ingredientName: string;
        healthLabel: string;
        effect: Effect;
        filters: ExecutionFilters;
    }>(),
);

export const getHealthLabelSummarySuccess = createAction(
    CompoundReportActionTypes.GET_HEALTH_LABEL_SUMMARY_SUCCESS,
    props<{ summary: Summary }>(),
);

export const getHealthLabelSummaryFailure = createAction(
    CompoundReportActionTypes.GET_HEALTH_LABEL_SUMMARY_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getStudyTypesRequest = createAction(
    CompoundReportActionTypes.GET_STUDY_TYPES_REQUEST,
    props<{ compoundId: string; ingredientName: string; filters: ExecutionFilters }>(),
);

export const getStudyTypesSuccess = createAction(
    CompoundReportActionTypes.GET_STUDY_TYPES_SUCCESS,
    props<{ studyTypes: StudyTypes }>(),
);

export const getStudyTypesFailure = createAction(
    CompoundReportActionTypes.GET_STUDY_TYPES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getStudyTypeRequest = createAction(
    CompoundReportActionTypes.GET_STUDY_TYPE_REQUEST,
    props<{
        compoundId: string;
        ingredientName: string;
        name: string;
        filters: ExecutionFilters;
    }>(),
);

export const getStudyTypeSuccess = createAction(
    CompoundReportActionTypes.GET_STUDY_TYPE_SUCCESS,
    props<{ studyType: StudyType }>(),
);

export const getStudyTypeFailure = createAction(
    CompoundReportActionTypes.GET_STUDY_TYPE_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const resetReport = createAction(CompoundReportActionTypes.RESET_REPORT);

export const clearNextError = createAction(CompoundReportActionTypes.CLEAR_NEXT_ERROR);
