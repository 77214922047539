/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as actions from './suggestions.actions';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { SuggestionsState } from './suggestions-state.interface';
import Suggestion from './interfaces/suggestion.interface';
import Ids from './enums/ids.enum';

export const initialState: SuggestionsState = {
    errors: [],
    loading: false,
    loaded: false,
    [Ids.source]: [],
    [Ids.target]: [],
    [Ids.target2]: [],
    [Ids.search]: [],
};

const suggestionReducer: ActionReducer<SuggestionsState, Action> = createReducer(
    initialState,
    on(actions.getSuggestionsRequest, (state: SuggestionsState) => ({
        ...state,
    })),
    on(
        actions.getSuggestionsSuccess,
        (
            state: SuggestionsState,
            { id, suggestions }: { id: string; suggestions: Suggestion[] },
        ) => ({
            ...state,
            loading: false,
            loaded: true,
            [id]: suggestions,
        }),
    ),
    on(
        actions.getSuggestionsFailure,
        (state: SuggestionsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(actions.clearNextError, (state: SuggestionsState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (state: SuggestionsState | undefined, action: Action): SuggestionsState =>
    suggestionReducer(state, action);

// selectors
export const getSuggestions: (state: SuggestionsState, id: string) => Suggestion[] = (
    state: SuggestionsState,
    id: Ids.source | Ids.target | Ids.target2 | Ids.search,
) => state[id];
export const getErrors: (state: SuggestionsState) => ErrorResponse[] = (state: SuggestionsState) =>
    state.errors;
export const getLoading: (state: SuggestionsState) => boolean = (state: SuggestionsState) =>
    state.loading;
export const getLoaded: (state: SuggestionsState) => boolean = (state: SuggestionsState) =>
    state.loaded;
