import { createSelector } from '@ngrx/store';
import { DataState } from '../data-state.interface';
import { getDataState } from './../index';
import * as fromAuth from './auth.reducer';

export const getAuthState = createSelector(getDataState, (state: DataState) => state.auth);

export const getAuthCurrentUser = createSelector(getAuthState, fromAuth.getAuthCurrentUser);

export const getAuthError = createSelector(getAuthState, fromAuth.getAuthError);

export const getAuthJwt = createSelector(getAuthState, fromAuth.getAuthJwt);

export const getAuthLoading = createSelector(getAuthState, fromAuth.getAuthLoading);

export const getAuthLoaded = createSelector(getAuthState, fromAuth.getAuthLoaded);
