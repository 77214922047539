export enum CompoundReportActionTypes {
    GET_OVERVIEW_REQUEST = '[COMPOUND REPORT][OVERVIEW] Get Overview Request',
    GET_OVERVIEW_SUCCESS = '[COMPOUND REPORT][OVERVIEW] Get Overview Success',
    GET_OVERVIEW_FAILURE = '[COMPOUND REPORT][OVERVIEW] Get Overview Failure',
    GET_RELATIONSHIP_GROUPS_REQUEST = '[COMPOUND REPORT][RELATIONSHIP GROUPS] Get Relationship Groups Request',
    GET_RELATIONSHIP_GROUPS_SUCCESS = '[COMPOUND REPORT][RELATIONSHIP GROUPS] Get Relationship Groups Success',
    GET_RELATIONSHIP_GROUPS_FAILURE = '[COMPOUND REPORT][RELATIONSHIP GROUPS] Get Relationship Groups Failure',
    GET_RELATIONSHIP_GROUP_SUMMARIES_REQUEST = '[COMPOUND REPORT][RELATIONSHIP GROUPS] Get Relationship Group Summaries Request',
    GET_RELATIONSHIP_GROUP_SUMMARIES_SUCCESS = '[COMPOUND REPORT][RELATIONSHIP GROUPS] Get Relationship Group Summaries Success',
    GET_RELATIONSHIP_GROUP_SUMMARIES_FAILURE = '[COMPOUND REPORT][RELATIONSHIP GROUPS] Get Relationship Group Summaries Failure',
    GET_EFFECT_SUMMARIES_REQUEST = '[COMPOUND REPORT][EFFECTS] Get Effect Summaries Request',
    GET_EFFECT_SUMMARIES_SUCCESS = '[COMPOUND REPORT][EFFECTS] Get Effect Summaries Success',
    GET_EFFECT_SUMMARIES_FAILURE = '[COMPOUND REPORT][EFFECTS] Get Effect Summaries Failure',
    GET_HEALTH_LABELS_REQUEST = '[COMPOUND REPORT][HEALTH LABELS] Get Health Labels Request',
    GET_HEALTH_LABELS_SUCCESS = '[COMPOUND REPORT][HEALTH LABELS] Get Health Labels Success',
    GET_HEALTH_LABELS_FAILURE = '[COMPOUND REPORT][HEALTH LABELS] Get Health Labels Failure',
    GET_HEALTH_LABEL_SUMMARY_REQUEST = '[COMPOUND REPORT][HEALTH LABELS] Get Health Label Summary Request',
    GET_HEALTH_LABEL_SUMMARY_SUCCESS = '[COMPOUND REPORT][HEALTH LABELS] Get Health Label Summary Success',
    GET_HEALTH_LABEL_SUMMARY_FAILURE = '[COMPOUND REPORT][HEALTH LABELS] Get Health Label Summary Failure',
    GET_STUDY_TYPES_REQUEST = '[COMPOUND REPORT][STUDY TYPES] Get Study Types Request',
    GET_STUDY_TYPES_SUCCESS = '[COMPOUND REPORT][STUDY TYPES] Get Study Types Success',
    GET_STUDY_TYPES_FAILURE = '[COMPOUND REPORT][STUDY TYPES] Get Study Types Failure',
    GET_STUDY_TYPE_REQUEST = '[COMPOUND REPORT][STUDY TYPES] Get Study Type Request',
    GET_STUDY_TYPE_SUCCESS = '[COMPOUND REPORT][STUDY TYPES] Get Study Type Success',
    GET_STUDY_TYPE_FAILURE = '[COMPOUND REPORT][STUDY TYPES] Get Study Type Failure',
    RESET_REPORT = '[COMPOUND REPORT] Reset Report',
    CLEAR_NEXT_ERROR = '[COMPOUND REPORT] Clear Next Error',
}
