/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import Project from './interfaces/project.interface';
import { ProjectsState } from './projects-state.interface';
import {
    getProjectsRequest,
    getProjectRequest,
    getProjectsWithBookmarkRequest,
    getProjectsWithoutBookmarkRequest,
    createProjectRequest,
    cloneProjectRequest,
    updateProjectRequest,
    toggleFavoriteProjectRequest,
    deleteProjectRequest,
    downloadProjectRequest,
    clearNextSuccess,
    clearNextError,
    clearNameUnavailableError,
    clearShouldFetchProjects,
} from './projects.actions';
import {
    getProjects,
    getProject,
    getProjectsWithBookmark,
    getProjectsWithoutBookmark,
    getProjectsLoading,
    getProjectsLoaded,
    getProjectLoading,
    getProjectLoaded,
    getUpdatingProject,
    getDeletingProject,
    getNameUnavailableError,
    getShouldFetchProjects,
    getErrors,
    getSuccess,
    getPageIndex,
    getPageSize,
    getTotal,
    getBlob,
} from './projects.selectors';
import ProjectProperties from './interfaces/project-properties.interface';
import BookmarkConfiguration from '../bookmarks/interfaces/configuration.interface';
import Alert from '@leap-store/core/src/lib/ui/alerts/interfaces/alert.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';

@Injectable()
export class ProjectsFacade {
    projects$: Observable<Project[]> = this.store.pipe(select(getProjects));
    project$: Observable<Project> = this.store.pipe(select(getProject));
    projectsWithBookmark$: Observable<Project[]> = this.store.pipe(select(getProjectsWithBookmark));
    projectsWithoutBookmark$: Observable<Project[]> = this.store.pipe(
        select(getProjectsWithoutBookmark),
    );
    projectsLoading$: Observable<boolean> = this.store.pipe(select(getProjectsLoading));
    projectsLoaded$: Observable<boolean> = this.store.pipe(select(getProjectsLoaded));
    projectLoading$: Observable<boolean> = this.store.pipe(select(getProjectLoading));
    projectLoaded$: Observable<boolean> = this.store.pipe(select(getProjectLoaded));
    updatingProject$: Observable<boolean> = this.store.pipe(select(getUpdatingProject));
    deletingProject$: Observable<boolean> = this.store.pipe(select(getDeletingProject));
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    success$: Observable<Project[]> = this.store.pipe(select(getSuccess));
    nameUnavailableError$: Observable<boolean> = this.store.pipe(select(getNameUnavailableError));
    shouldFetchProjects$: Observable<boolean> = this.store.pipe(select(getShouldFetchProjects));
    pageIndex$: Observable<number> = this.store.pipe(select(getPageIndex));
    pageSize$: Observable<number> = this.store.pipe(select(getPageSize));
    total$: Observable<number> = this.store.pipe(select(getTotal));
    blob$: Observable<Blob> = this.store.pipe(select(getBlob));

    constructor(private store: Store<ProjectsState>) {}

    getProjects(
        pageIndex: number,
        pageSize: number,
        sortDirection?: SortingOrder,
        sortColumn?: string,
        typeFilter?: string,
        statusFilter?: string[],
        searchFilter?: string,
    ): void {
        this.store.dispatch(
            getProjectsRequest({
                pageIndex,
                pageSize,
                sortDirection,
                sortColumn,
                typeFilter,
                statusFilter,
                searchFilter,
            }),
        );
    }

    getProjectsWithBookmark(configuration: BookmarkConfiguration): void {
        this.store.dispatch(getProjectsWithBookmarkRequest({ configuration }));
    }

    getProjectsWithoutBookmark(
        configuration: BookmarkConfiguration,
        search: string,
        pageIndex: number,
        pageSize: number,
        sortDirection?: SortingOrder,
        sortColumn?: string,
    ): void {
        this.store.dispatch(
            getProjectsWithoutBookmarkRequest({
                configuration,
                search,
                pageIndex,
                pageSize,
                sortDirection,
                sortColumn,
            }),
        );
    }

    getProject(id: string): void {
        this.store.dispatch(getProjectRequest({ id }));
    }

    createProject(name?: string, suppressAlert?: boolean): void {
        this.store.dispatch(createProjectRequest({ name, suppressAlert }));
    }

    cloneProject(id: string, originalProjectName: string): void {
        this.store.dispatch(cloneProjectRequest({ id, originalProjectName }));
    }

    updateProject(id: string, project: ProjectProperties, successMessage?: Alert): void {
        this.store.dispatch(updateProjectRequest({ id, project, successMessage }));
    }

    toggleFavoriteProject(id: string, favorite: boolean): void {
        this.store.dispatch(toggleFavoriteProjectRequest({ id, favorite }));
    }

    deleteProject(id: string, name: string): void {
        this.store.dispatch(deleteProjectRequest({ id, name }));
    }

    downloadProject(id: string, preferences?: UserPreferences): void {
        this.store.dispatch(downloadProjectRequest({ id, preferences }));
    }

    clearNextSuccess(): void {
        this.store.dispatch(clearNextSuccess());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }

    clearNameUnavailableError(): void {
        this.store.dispatch(clearNameUnavailableError());
    }

    clearShouldFetchProjects(): void {
        this.store.dispatch(clearShouldFetchProjects());
    }
}
