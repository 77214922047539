/** third-party imports */
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

/** custom imports */
import * as actions from './metadata.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { MetadataService } from './services/metadata.service';
import { BookmarksService } from '@leap-store/core/src/lib/data/bookmarks/services/bookmarks.service';
import Identifiers from './types/identifiers.type';
import OriginsInfoPerRelationship from './types/origins-info-per-relationship.type';
import UnitConversion from './interfaces/unit-conversion.interface';
import ProteinOrigins from './types/protein-origins.type';
import PatentsCounts from './types/patents-counts.type';
import ContainingIngredients from './types/containing-ingredients.type';

@Injectable()
export class MetadataEffects {
    constructor(
        private actions$: Actions,
        private metadataService: MetadataService,
        private bookmarksService: BookmarksService,
    ) {}

    getLabs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getLabsRequest),
            switchMap(() =>
                this.metadataService.getLabs().pipe(
                    map((labs: string[]) => actions.getLabsSuccess({ labs })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getLabsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getHealthLabels$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getHealthLabelsRequest),
            switchMap(() =>
                this.metadataService.getHealthLabels().pipe(
                    map((healthLabels: string[]) =>
                        actions.getHealthLabelsSuccess({ healthLabels }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getHealthLabelsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getIdentifiers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getIdentifiersRequest),
            switchMap(({ ids, bookmarkIds }) =>
                (bookmarkIds
                    ? this.bookmarksService.getIdentifiers(bookmarkIds)
                    : this.metadataService.getIdentifiers(ids)
                ).pipe(
                    map((identifiers: Identifiers) =>
                        actions.getIdentifiersSuccess({ identifiers }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getIdentifiersFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getRelationshipsInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getRelationshipsInfoRequest),
            switchMap(({ ids, bookmarkIds }) =>
                (bookmarkIds
                    ? this.bookmarksService.getSourceInfoPerRelationship(bookmarkIds, ids)
                    : this.metadataService.getSourceInfoPerRelationship(ids)
                ).pipe(
                    map((relationshipsInfo: Record<string, OriginsInfoPerRelationship>) =>
                        actions.getRelationshipsInfoSuccess({ relationshipsInfo }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getRelationshipsInfoFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getStudyTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getStudyTypesRequest),
            switchMap(() =>
                this.metadataService.getStudyTypes().pipe(
                    map((studyTypes: string[]) => actions.getStudyTypesSuccess({ studyTypes })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getStudyTypesFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getJournals$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getJournalsRequest),
            switchMap(() =>
                this.metadataService.getJournals().pipe(
                    map((journals: string[]) => actions.getJournalsSuccess({ journals })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getJournalsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getUnitConversions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getUnitConversionsRequest),
            switchMap(() =>
                this.metadataService.getUnitConversions().pipe(
                    map((unitConversions: UnitConversion[]) =>
                        actions.getUnitConversionsSuccess({ unitConversions }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getUnitConversionsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getProteinOrigins$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getProteinOriginsRequest),
            switchMap(({ ids }) =>
                this.metadataService.getProteinOrigins(ids).pipe(
                    map((proteinOrigins: ProteinOrigins) =>
                        actions.getProteinOriginsSuccess({ proteinOrigins }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getProteinOriginsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getPatentsCounts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getPatentsCountsRequest),
            switchMap(({ ids }) =>
                this.metadataService.getPatentsCounts(ids).pipe(
                    map((patentsCounts: PatentsCounts) =>
                        actions.getPatentsCountsSuccess({ patentsCounts }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getPatentsCountsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getContainingIngredients$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getContainingIngredientsRequest),
            switchMap(({ ids, bookmarkIds }) =>
                (bookmarkIds
                    ? this.bookmarksService.getContainingIngredients(bookmarkIds)
                    : this.metadataService.getContainingIngredients(ids)
                ).pipe(
                    map((containingIngredients: ContainingIngredients) =>
                        actions.getContainingIngredientsSuccess({ containingIngredients }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        // Handles older bookmarks created on the BE before the integration of containing ingredients which return 404
                        // They are not flagged with the legacy flag and thus could not be handled as other cases
                        bookmarkIds && errorResponse.status === 404
                            ? of(
                                  actions.getContainingIngredientsSuccess({
                                      containingIngredients: null,
                                  }),
                              )
                            : of(actions.getContainingIngredientsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
