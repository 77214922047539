/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/**  custom imports */
import { CompoundReportState } from './compound-report-state.interface';
import {
    getOverviewRequest,
    getRelationshipGroupsRequest,
    getRelationshipGroupSummariesRequest,
    getEffectSummariesRequest,
    getHealthLabelsRequest,
    getHealthLabelSummaryRequest,
    getStudyTypesRequest,
    getStudyTypeRequest,
    resetReport,
    clearNextError,
} from './compound-report.actions';
import {
    getErrors,
    getOverview,
    getOverviewLoading,
    getOverviewLoaded,
    getRelationshipGroups,
    getRelationshipGroupsLoading,
    getRelationshipGroupsLoaded,
    getRelationshipGroupSummaries,
    getEffectSummaries,
    getHealthLabels,
    getHealthLabelsLoading,
    getHealthLabelsLoaded,
    getHealthLabelSummary,
    getStudyTypes,
    getStudyTypesLoading,
    getStudyTypesLoaded,
    getStudyType,
    getTotal,
} from './compound-report.selectors';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import Overview from './interfaces/overview.interface';
import RelationshipGroups from './interfaces/relationship-groups.interface';
import RelationshipGroup from '../ingredient-profiler/enums/relationship-group.enum';
import Effect from '../ingredient-profiler/enums/effect.enum';
import EffectSummaries from './interfaces/effect-summaries.interface';
import HealthLabel from './interfaces/health-label.interface';
import StudyTypes from './interfaces/study-types.interface';
import StudyType from './interfaces/study-type.interface';
import RelationshipGroupSummaries from './interfaces/relationship-group-summaries.interface';
import Summary from '../report/interfaces/summary.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';

@Injectable()
export class CompoundReportFacade {
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    overview$: Observable<Overview> = this.store.pipe(select(getOverview));
    overviewLoading$: Observable<boolean> = this.store.pipe(select(getOverviewLoading));
    overviewLoaded$: Observable<boolean> = this.store.pipe(select(getOverviewLoaded));
    relationshipGroups$: Observable<RelationshipGroups> = this.store.pipe(
        select(getRelationshipGroups),
    );
    relationshipGroupsLoading$: Observable<boolean> = this.store.pipe(
        select(getRelationshipGroupsLoading),
    );
    relationshipGroupsLoaded$: Observable<boolean> = this.store.pipe(
        select(getRelationshipGroupsLoaded),
    );
    relationshipGroupSummaries$: Observable<RelationshipGroupSummaries> = this.store.pipe(
        select(getRelationshipGroupSummaries),
    );
    effectSummaries$: Observable<EffectSummaries> = this.store.pipe(select(getEffectSummaries));
    healthLabels$: Observable<HealthLabel[]> = this.store.pipe(select(getHealthLabels));
    healthLabelsLoading$: Observable<boolean> = this.store.pipe(select(getHealthLabelsLoading));
    healthLabelsLoaded$: Observable<boolean> = this.store.pipe(select(getHealthLabelsLoaded));
    healthLabelSummary$: Observable<Summary> = this.store.pipe(select(getHealthLabelSummary));
    studyTypes$: Observable<StudyTypes> = this.store.pipe(select(getStudyTypes));
    studyTypesLoading$: Observable<boolean> = this.store.pipe(select(getStudyTypesLoading));
    studyTypesLoaded$: Observable<boolean> = this.store.pipe(select(getStudyTypesLoaded));
    studyType$: Observable<StudyType> = this.store.pipe(select(getStudyType));
    total$: Observable<number> = this.store.pipe(select(getTotal));

    constructor(private store: Store<CompoundReportState>) {}

    getOverview({
        compoundId,
        ingredientName,
        filters,
        areEffectsEnabled,
    }: {
        compoundId: string;
        ingredientName: string;
        filters?: ExecutionFilters;
        areEffectsEnabled?: boolean;
    }): void {
        this.store.dispatch(
            getOverviewRequest({ compoundId, ingredientName, filters, areEffectsEnabled }),
        );
    }

    getRelationshipGroups({
        compoundId,
        ingredientName,
        filters,
    }: {
        compoundId: string;
        ingredientName: string;
        filters?: ExecutionFilters;
    }): void {
        this.store.dispatch(getRelationshipGroupsRequest({ compoundId, ingredientName, filters }));
    }

    getRelationshipGroupSummaries({
        compoundId,
        ingredientName,
        relationshipGroup,
        filters,
    }: {
        compoundId: string;
        ingredientName: string;
        relationshipGroup: RelationshipGroup;
        filters?: ExecutionFilters;
    }): void {
        this.store.dispatch(
            getRelationshipGroupSummariesRequest({
                compoundId,
                ingredientName,
                relationshipGroup,
                filters,
            }),
        );
    }

    getEffectSummaries({
        compoundId,
        ingredientName,
        effect,
        filters,
    }: {
        compoundId: string;
        ingredientName: string;
        effect: Effect;
        filters?: ExecutionFilters;
    }): void {
        this.store.dispatch(
            getEffectSummariesRequest({ compoundId, ingredientName, effect, filters }),
        );
    }

    getHealthLabels({
        compoundId,
        ingredientName,
        filters,
        areEffectsEnabled,
    }: {
        compoundId: string;
        ingredientName: string;
        filters?: ExecutionFilters;
        areEffectsEnabled?: boolean;
    }): void {
        this.store.dispatch(
            getHealthLabelsRequest({ compoundId, ingredientName, filters, areEffectsEnabled }),
        );
    }

    getHealthLabelSummary({
        compoundId,
        ingredientName,
        healthLabel,
        effect,
        filters,
    }: {
        compoundId: string;
        ingredientName: string;
        healthLabel: string;
        effect?: Effect;
        filters?: ExecutionFilters;
    }): void {
        this.store.dispatch(
            getHealthLabelSummaryRequest({
                compoundId,
                ingredientName,
                healthLabel,
                effect,
                filters,
            }),
        );
    }

    getStudyTypes({
        compoundId,
        ingredientName,
        filters,
    }: {
        compoundId: string;
        ingredientName: string;
        filters?: ExecutionFilters;
    }): void {
        this.store.dispatch(getStudyTypesRequest({ compoundId, ingredientName, filters }));
    }

    getStudyType({
        compoundId,
        ingredientName,
        name,
        filters,
    }: {
        compoundId: string;
        ingredientName: string;
        name: string;
        filters?: ExecutionFilters;
    }): void {
        this.store.dispatch(getStudyTypeRequest({ compoundId, ingredientName, name, filters }));
    }

    resetReport(): void {
        this.store.dispatch(resetReport());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
