import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectsActionTypes } from './projects-action-types.enum';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import Project from './interfaces/project.interface';
import ProjectProperties from './interfaces/project-properties.interface';
import BookmarkConfiguration from '../bookmarks/interfaces/configuration.interface';
import Alert from '@leap-store/core/src/lib/ui/alerts/interfaces/alert.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';

export const getProjectsRequest = createAction(
    ProjectsActionTypes.GET_PROJECTS_REQUEST,
    props<{
        pageIndex: number;
        pageSize: number;
        sortDirection: SortingOrder;
        sortColumn: string;
        typeFilter: string;
        statusFilter: string[];
        searchFilter: string;
    }>(),
);

export const getProjectsSuccess = createAction(
    ProjectsActionTypes.GET_PROJECTS_SUCCESS,
    props<{
        paginatedProjects: PaginatedResults<Project>;
        sortDirection: SortingOrder;
        sortColumn: string;
    }>(),
);

export const getProjectsFailure = createAction(
    ProjectsActionTypes.GET_PROJECTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getProjectRequest = createAction(
    ProjectsActionTypes.GET_PROJECT_REQUEST,
    props<{ id: string }>(),
);

export const getProjectSuccess = createAction(
    ProjectsActionTypes.GET_PROJECT_SUCCESS,
    props<{ project: Project }>(),
);

export const getProjectFailure = createAction(
    ProjectsActionTypes.GET_PROJECT_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getProjectsWithBookmarkRequest = createAction(
    ProjectsActionTypes.GET_PROJECTS_WITH_BOOKMARK_REQUEST,
    props<{ configuration: BookmarkConfiguration }>(),
);

export const getProjectsWithBookmarkSuccess = createAction(
    ProjectsActionTypes.GET_PROJECTS_WITH_BOOKMARK_SUCCESS,
    props<{
        projects: Project[];
    }>(),
);

export const getProjectsWithBookmarkFailure = createAction(
    ProjectsActionTypes.GET_PROJECTS_WITH_BOOKMARK_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getProjectsWithoutBookmarkRequest = createAction(
    ProjectsActionTypes.GET_PROJECTS_WITHOUT_BOOKMARK_REQUEST,
    props<{
        configuration: BookmarkConfiguration;
        search: string;
        pageIndex: number;
        pageSize: number;
        sortDirection: SortingOrder;
        sortColumn: string;
    }>(),
);

export const getProjectsWithoutBookmarkSuccess = createAction(
    ProjectsActionTypes.GET_PROJECTS_WITHOUT_BOOKMARK_SUCCESS,
    props<{
        paginatedProjects: PaginatedResults<Project>;
    }>(),
);

export const getProjectsWithoutBookmarkFailure = createAction(
    ProjectsActionTypes.GET_PROJECTS_WITHOUT_BOOKMARK_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const createProjectRequest = createAction(
    ProjectsActionTypes.CREATE_PROJECT_REQUEST,
    props<{ name?: string; suppressAlert?: boolean }>(),
);

export const createProjectSuccess = createAction(
    ProjectsActionTypes.CREATE_PROJECT_SUCCESS,
    props<{ project: Project; suppressAlert?: boolean }>(),
);

export const createProjectFailure = createAction(
    ProjectsActionTypes.CREATE_PROJECT_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const cloneProjectRequest = createAction(
    ProjectsActionTypes.CLONE_PROJECT_REQUEST,
    props<{ id: string; originalProjectName: string }>(),
);

export const cloneProjectSuccess = createAction(
    ProjectsActionTypes.CLONE_PROJECT_SUCCESS,
    props<{ project: Project; originalProjectName: string }>(),
);

export const cloneProjectFailure = createAction(
    ProjectsActionTypes.CLONE_PROJECT_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const updateProjectRequest = createAction(
    ProjectsActionTypes.UPDATE_PROJECT_REQUEST,
    props<{ id: string; project: ProjectProperties; successMessage: Alert }>(),
);

export const updateProjectSuccess = createAction(
    ProjectsActionTypes.UPDATE_PROJECT_SUCCESS,
    props<{ project: Project; successMessage: Alert }>(),
);

export const updateProjectFailure = createAction(
    ProjectsActionTypes.UPDATE_PROJECT_FAILURE,
    props<{ id: string; errorResponse: HttpErrorResponse }>(),
);

export const toggleFavoriteProjectRequest = createAction(
    ProjectsActionTypes.TOGGLE_FAVORITE_PROJECT_REQUEST,
    props<{ id: string; favorite: boolean }>(),
);

export const toggleFavoriteProjectSuccess = createAction(
    ProjectsActionTypes.TOGGLE_FAVORITE_PROJECT_SUCCESS,
    props<{ project: Project }>(),
);

export const toggleFavoriteProjectFailure = createAction(
    ProjectsActionTypes.TOGGLE_FAVORITE_PROJECT_FAILURE,
    props<{ id: string; errorResponse: HttpErrorResponse }>(),
);

export const deleteProjectRequest = createAction(
    ProjectsActionTypes.DELETE_PROJECT_REQUEST,
    props<{ id: string; name: string }>(),
);

export const deleteProjectSuccess = createAction(
    ProjectsActionTypes.DELETE_PROJECT_SUCCESS,
    props<{ id: string; name: string }>(),
);

export const deleteProjectFailure = createAction(
    ProjectsActionTypes.DELETE_PROJECT_FAILURE,
    props<{ id: string; errorResponse: HttpErrorResponse }>(),
);

export const downloadProjectRequest = createAction(
    ProjectsActionTypes.DOWNLOAD_PROJECT_REQUEST,
    props<{ id: string; preferences: UserPreferences }>(),
);

export const downloadProjectSuccess = createAction(
    ProjectsActionTypes.DOWNLOAD_PROJECT_SUCCESS,
    props<{ blob: Blob }>(),
);

export const downloadProjectFailure = createAction(
    ProjectsActionTypes.DOWNLOAD_PROJECT_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearNextSuccess = createAction(ProjectsActionTypes.CLEAR_NEXT_SUCCESS);

export const clearNextError = createAction(ProjectsActionTypes.CLEAR_NEXT_ERROR);

export const clearNameUnavailableError = createAction(
    ProjectsActionTypes.CLEAR_NAME_UNAVAILABLE_ERROR,
);

export const clearShouldFetchProjects = createAction(
    ProjectsActionTypes.CLEAR_SHOULD_FETCH_PROJECTS,
);
