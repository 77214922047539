/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as profilerActions from './ingredient-profiler.actions';
import { IngredientProfilerState } from './ingredient-profiler-state.interface';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import Suggestion from '../discovery/suggestions/interfaces/suggestion.interface';
import PaginatedInsights from './interfaces/paginated-insights.interface';
import Insight from './interfaces/insight.interface';
import PaginatedCompounds from './interfaces/paginated-compounds.interface';
import CustomIngredient from './interfaces/custom-ingredient.interface';
import RelationshipGroup from './enums/relationship-group.enum';
import Overview from './interfaces/overview.interface';
import HealthLabelStatistics from './interfaces/health-label-statistics.interface';
import HealthLabelSummaries from './interfaces/health-label-summaries.interface';
import HealthLabelTopCompounds from './interfaces/health-label-top-compounds.interface';

export const initialState: IngredientProfilerState = {
    errors: [],
    suggestions: [],
    suggestionsLoading: false,
    suggestionsLoaded: false,
    customIngredient: null,
    customIngredientLoading: false,
    customIngredientLoaded: false,
    compoundsTotal: 0,
    compoundsDisplaying: 0,
    compoundsLoading: false,
    compoundsLoaded: false,
    minSourceConcentration: null,
    maxSourceConcentration: null,
    parentCompoundId: '',
    insights: [],
    insightsTotal: 0,
    insightsDisplaying: 0,
    insightsPageIndex: 0,
    insightsLoading: false,
    insightsLoaded: false,
    ingredientInsightsTotal: 0,
    ingredientInsightsLoading: false,
    ingredientInsightsLoaded: false,
    targetInsights: null,
    targetInsightsTotal: 0,
    targetInsightsLoading: false,
    targetInsightsLoaded: false,
    oldestOccurrence: null,
    newestOccurrence: null,
    shouldShowSourceConcentration: false,
    isCustomIngredient: false,
    blob: null,
    categories: null,
    categoriesLoading: false,
    categoriesLoaded: false,
    overview: null,
    overviewLoading: false,
    overviewLoaded: false,
    healthLabelsStatistics: null,
    healthLabelsStatisticsLoading: false,
    healthLabelsStatisticsLoaded: false,
    relationshipsPerGroup: null,
    healthLabelSummaries: null,
    healthLabelTopCompounds: null,
    searchSuggestions: [],
    searchSuggestionsLoading: false,
    searchSuggestionsLoaded: false,
};

const profilerReducer: ActionReducer<IngredientProfilerState, Action> = createReducer(
    initialState,
    on(profilerActions.getSuggestionsRequest, (state: IngredientProfilerState) => ({
        ...state,
        suggestionsLoading: true,
        suggestionsLoaded: false,
    })),
    on(
        profilerActions.getSuggestionsSuccess,
        (state: IngredientProfilerState, { suggestions }: { suggestions: Suggestion[] }) => ({
            ...state,
            suggestions,
            suggestionsLoading: false,
            suggestionsLoaded: true,
        }),
    ),
    on(
        profilerActions.getSuggestionsFailure,
        (
            state: IngredientProfilerState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            suggestionsLoading: false,
            suggestionsLoaded: false,
        }),
    ),
    on(profilerActions.getCustomIngredientRequest, (state: IngredientProfilerState) => ({
        ...state,
        customIngredientLoading: true,
        customIngredientLoaded: false,
    })),
    on(
        profilerActions.getCustomIngredientSuccess,
        (
            state: IngredientProfilerState,
            { customIngredient }: { customIngredient: CustomIngredient },
        ) => ({
            ...state,
            customIngredient,
            customIngredientLoading: false,
            customIngredientLoaded: true,
        }),
    ),
    on(
        profilerActions.getCustomIngredientFailure,
        (
            state: IngredientProfilerState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => {
            const error: ErrorResponse = {
                errors: [
                    {
                        code: errorResponse.error?.errors?.[0]?.code,
                        message: 'file-type-error',
                        type: errorResponse.error?.errors?.[0]?.type,
                    },
                ],
            };

            return {
                ...state,
                errors: [
                    ...state.errors,
                    errorResponse.status === 400 ? error : errorResponse.error,
                ],
                customIngredientLoading: false,
                customIngredientLoaded: false,
            };
        },
    ),
    on(profilerActions.quitCustomIngredientRequest, (state: IngredientProfilerState) => ({
        ...state,
        customIngredient: null as CustomIngredient,
        customIngredientLoading: false,
        customIngredientLoaded: false,
    })),
    on(profilerActions.getCompoundsRequest, (state: IngredientProfilerState) => ({
        ...state,
        compoundsLoading: true,
        compoundsLoaded: false,
    })),
    on(
        profilerActions.getCompoundsSuccess,
        (
            state: IngredientProfilerState,
            { paginatedCompounds }: { paginatedCompounds: PaginatedCompounds },
        ) => ({
            ...state,
            oldestOccurrence: paginatedCompounds.oldestOccurrence,
            newestOccurrence: paginatedCompounds.newestOccurrence,
            minSourceConcentration: paginatedCompounds.minSourceConcentration,
            maxSourceConcentration: paginatedCompounds.maxSourceConcentration,
            shouldShowSourceConcentration: paginatedCompounds.shouldShowSourceConcentration,
            compoundsTotal: paginatedCompounds.total,
            compoundsDisplaying: paginatedCompounds.displayingCompounds,
            isCustomIngredient: paginatedCompounds.isCustomIngredient,
            compoundsLoading: false,
            compoundsLoaded: true,
        }),
    ),
    on(
        profilerActions.getCompoundsFailure,
        (
            state: IngredientProfilerState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            compoundsLoading: false,
            compoundsLoaded: false,
        }),
    ),
    on(
        profilerActions.getInsightsRequest,
        (state: IngredientProfilerState, { compoundId }: { compoundId: string }) => ({
            ...state,
            parentCompoundId: compoundId,
            insightsLoading: true,
            insightsLoaded: false,
        }),
    ),
    on(
        profilerActions.getInsightsSuccess,
        (
            state: IngredientProfilerState,
            {
                paginatedInsights,
                compoundId,
            }: { paginatedInsights: PaginatedInsights; compoundId: string },
        ) => ({
            ...state,
            parentCompoundId: compoundId,
            insights: paginatedInsights.results,
            insightsPageIndex: paginatedInsights.pageIndex,
            insightsTotal: paginatedInsights.total,
            insightsDisplaying: paginatedInsights.displayingInsights,
            insightsLoading: false,
            insightsLoaded: true,
        }),
    ),
    on(
        profilerActions.getInsightsFailure,
        (
            state: IngredientProfilerState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            insightsLoading: false,
            insightsLoaded: false,
        }),
    ),
    on(profilerActions.getIngredientInsightsRequest, (state: IngredientProfilerState) => ({
        ...state,
        ingredientInsightsLoading: true,
        ingredientInsightsLoaded: false,
    })),
    on(
        profilerActions.getIngredientInsightsSuccess,
        (
            state: IngredientProfilerState,
            { paginatedInsights }: { paginatedInsights: PaginatedInsights },
        ) => ({
            ...state,
            ingredientInsightsTotal: paginatedInsights.total,
            ingredientInsightsLoading: false,
            ingredientInsightsLoaded: true,
        }),
    ),
    on(
        profilerActions.getIngredientInsightsFailure,
        (
            state: IngredientProfilerState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            ingredientInsightsLoading: false,
            ingredientInsightsLoaded: false,
        }),
    ),
    on(profilerActions.getTargetInsightsRequest, (state: IngredientProfilerState) => ({
        ...state,
        targetInsightsLoading: true,
        targetInsightsLoaded: false,
    })),
    on(
        profilerActions.getTargetInsightsSuccess,
        (
            state: IngredientProfilerState,
            { paginatedInsights }: { paginatedInsights: PaginatedInsights },
        ) => ({
            ...state,
            targetInsights: paginatedInsights.results,
            targetInsightsTotal: paginatedInsights.total,
            targetInsightsLoading: false,
            targetInsightsLoaded: true,
        }),
    ),
    on(
        profilerActions.getTargetInsightsFailure,
        (
            state: IngredientProfilerState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            targetInsightsLoading: false,
            targetInsightsLoaded: false,
        }),
    ),
    on(profilerActions.downloadInsightsRequest, (state: IngredientProfilerState) => ({
        ...state,
        blob: null as Blob,
    })),
    on(
        profilerActions.downloadInsightsSuccess,
        (state: IngredientProfilerState, { blob }: { blob: Blob }) => ({
            ...state,
            blob,
        }),
    ),
    on(
        profilerActions.downloadInsightsFailure,
        (
            state: IngredientProfilerState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(profilerActions.getCategoriesRequest, (state: IngredientProfilerState) => ({
        ...state,
        categories: {},
        categoriesLoading: true,
        categoriesLoaded: false,
    })),
    on(
        profilerActions.getCategoriesSuccess,
        (
            state: IngredientProfilerState,
            { categories }: { categories: Record<string, string[]> },
        ) => ({
            ...state,
            categories,
            categoriesLoading: false,
            categoriesLoaded: true,
        }),
    ),
    on(
        profilerActions.getCategoriesFailure,
        (
            state: IngredientProfilerState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            categoriesLoading: false,
            categoriesLoaded: false,
        }),
    ),
    on(profilerActions.getOverviewRequest, (state: IngredientProfilerState) => ({
        ...state,
        overviewLoading: true,
        overviewLoaded: false,
    })),
    on(
        profilerActions.getOverviewSuccess,
        (state: IngredientProfilerState, { overview }: { overview: Overview }) => ({
            ...state,
            overview,
            overviewLoading: false,
            overviewLoaded: true,
        }),
    ),
    on(
        profilerActions.getOverviewFailure,
        (
            state: IngredientProfilerState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            overviewLoading: false,
            overviewLoaded: false,
        }),
    ),
    on(profilerActions.getHealthLabelsStatisticsRequest, (state: IngredientProfilerState) => ({
        ...state,
        healthLabelsStatisticsLoading: true,
        healthLabelsStatisticsLoaded: false,
        referencesLoading: true,
        referencesLoaded: false,
    })),
    on(
        profilerActions.getHealthLabelsStatisticsSuccess,
        (
            state: IngredientProfilerState,
            {
                healthLabelsStatistics,
                relationshipsPerGroup,
            }: {
                healthLabelsStatistics: HealthLabelStatistics[];
                relationshipsPerGroup: Record<RelationshipGroup, string[]>;
            },
        ) => ({
            ...state,
            healthLabelsStatistics,
            healthLabelsStatisticsLoading: false,
            healthLabelsStatisticsLoaded: true,
            relationshipsPerGroup,
            referencesLoading: false,
            referencesLoaded: true,
        }),
    ),
    on(
        profilerActions.getHealthLabelsStatisticsFailure,
        (
            state: IngredientProfilerState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            healthLabelsStatisticsLoading: false,
            healthLabelsStatisticsLoaded: false,
            referencesLoading: false,
            referencesLoaded: false,
        }),
    ),
    on(profilerActions.getHealthLabelSummariesRequest, (state: IngredientProfilerState) => ({
        ...state,
    })),
    on(
        profilerActions.getHealthLabelSummariesSuccess,
        (
            state: IngredientProfilerState,
            { healthLabelSummaries }: { healthLabelSummaries: HealthLabelSummaries },
        ) => ({
            ...state,
            healthLabelSummaries,
        }),
    ),
    on(
        profilerActions.getHealthLabelSummariesFailure,
        (
            state: IngredientProfilerState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(profilerActions.getHealthLabelTopCompoundsRequest, (state: IngredientProfilerState) => ({
        ...state,
    })),
    on(
        profilerActions.getHealthLabelTopCompoundsSuccess,
        (
            state: IngredientProfilerState,
            { healthLabelTopCompounds }: { healthLabelTopCompounds: HealthLabelTopCompounds },
        ) => ({
            ...state,
            healthLabelTopCompounds,
        }),
    ),
    on(
        profilerActions.getHealthLabelTopCompoundsFailure,
        (
            state: IngredientProfilerState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(profilerActions.getSearchSuggestionsRequest, (state: IngredientProfilerState) => ({
        ...state,
        searchSuggestionsLoading: true,
        searchSuggestionsLoaded: false,
    })),
    on(
        profilerActions.getSearchSuggestionsSuccess,
        (state: IngredientProfilerState, { suggestionIds }: { suggestionIds: string[] }) => ({
            ...state,
            searchSuggestions: suggestionIds,
            searchSuggestionsLoading: false,
            searchSuggestionsLoaded: true,
        }),
    ),
    on(
        profilerActions.getSearchSuggestionsFailure,
        (
            state: IngredientProfilerState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            searchSuggestionsLoading: false,
            searchSuggestionsLoaded: false,
        }),
    ),
    on(profilerActions.clearSearchSuggestions, (state: IngredientProfilerState) => ({
        ...state,
        searchSuggestions: null as string[],
        searchSuggestionsLoading: false,
        searchSuggestionsLoaded: false,
    })),
    on(profilerActions.resetDiscovery, (state: IngredientProfilerState) => ({
        ...state,
        customIngredient: null as CustomIngredient,
        customIngredientLoading: false,
        customIngredientLoaded: false,
        compoundsTotal: null as number,
        compoundsDisplaying: null as number,
        compoundsLoading: false,
        compoundsLoaded: false,
        minSourceConcentration: null as number,
        maxSourceConcentration: null as number,
        parentCompoundId: null as string,
        insights: null as Insight[],
        insightsTotal: null as number,
        insightsDisplaying: null as number,
        insightsPageIndex: null as number,
        insightsLoading: false,
        insightsLoaded: false,
        ingredientInsightsTotal: null as number,
        ingredientInsightsLoading: false,
        ingredientInsightsLoaded: false,
        targetInsights: null as Insight[],
        targetInsightsTotal: null as number,
        targetInsightsLoading: false,
        targetInsightsLoaded: false,
        displaying: null as number,
        total: null as number,
        shouldShowSourceConcentration: false,
        isCustomIngredient: false,
        blob: null as Blob,
        categories: null as Record<string, string[]>,
        categoriesLoading: false,
        categoriesLoaded: false,
        overview: null as Overview,
        overviewLoading: false as boolean,
        overviewLoaded: false as boolean,
        healthLabelsStatistics: null as HealthLabelStatistics[],
        healthLabelsStatisticsLoading: false as boolean,
        healthLabelsStatisticsLoaded: false as boolean,
        relationshipsPerGroup: null as Record<RelationshipGroup, string[]>,
        healthLabelSummaries: null as HealthLabelSummaries,
        healthLabelTopCompounds: null as HealthLabelTopCompounds,
        searchSuggestions: null as string[],
        searchSuggestionsLoading: false,
        searchSuggestionsLoaded: false,
    })),
    on(profilerActions.clearNextError, (state: IngredientProfilerState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (
    state: IngredientProfilerState | undefined,
    action: Action,
): IngredientProfilerState => profilerReducer(state, action);

// selectors
export const getSuggestions: (state: IngredientProfilerState) => Suggestion[] = (
    state: IngredientProfilerState,
) => state.suggestions;
export const getSuggestionsLoading: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.suggestionsLoading;
export const getSuggestionsLoaded: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.suggestionsLoaded;
export const getErrors: (state: IngredientProfilerState) => ErrorResponse[] = (
    state: IngredientProfilerState,
) => state.errors;
export const getCustomIngredient: (state: IngredientProfilerState) => CustomIngredient = (
    state: IngredientProfilerState,
) => state.customIngredient;
export const getCustomIngredientLoading: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.customIngredientLoading;
export const getCustomIngredientLoaded: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.customIngredientLoaded;
export const getCompoundsTotal: (state: IngredientProfilerState) => number = (
    state: IngredientProfilerState,
) => state.compoundsTotal;
export const getCompoundsDisplaying: (state: IngredientProfilerState) => number = (
    state: IngredientProfilerState,
) => state.compoundsDisplaying;
export const getCompoundsLoading: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.compoundsLoading;
export const getCompoundsLoaded: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.compoundsLoaded;
export const getMinSourceConcentration: (state: IngredientProfilerState) => number = (
    state: IngredientProfilerState,
) => state.minSourceConcentration;
export const getMaxSourceConcentration: (state: IngredientProfilerState) => number = (
    state: IngredientProfilerState,
) => state.maxSourceConcentration;
export const getParentCompoundId: (state: IngredientProfilerState) => string = (
    state: IngredientProfilerState,
) => state.parentCompoundId;
export const getInsights: (state: IngredientProfilerState) => Insight[] = (
    state: IngredientProfilerState,
) => state.insights;
export const getInsightsTotal: (state: IngredientProfilerState) => number = (
    state: IngredientProfilerState,
) => state.insightsTotal;
export const getInsightsDisplaying: (state: IngredientProfilerState) => number = (
    state: IngredientProfilerState,
) => state.insightsDisplaying;
export const getInsightsPageIndex: (state: IngredientProfilerState) => number = (
    state: IngredientProfilerState,
) => state.insightsPageIndex;
export const getInsightsLoading: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.insightsLoading;
export const getInsightsLoaded: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.insightsLoaded;
export const getIngredientInsightsTotal: (state: IngredientProfilerState) => number = (
    state: IngredientProfilerState,
) => state.ingredientInsightsTotal;
export const getIngredientInsightsLoading: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.ingredientInsightsLoading;
export const getIngredientInsightsLoaded: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.ingredientInsightsLoaded;
export const getTargetInsights: (state: IngredientProfilerState) => Insight[] = (
    state: IngredientProfilerState,
) => state.targetInsights;
export const getTargetInsightsTotal: (state: IngredientProfilerState) => number = (
    state: IngredientProfilerState,
) => state.targetInsightsTotal;
export const getTargetInsightsLoading: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.targetInsightsLoading;
export const getTargetInsightsLoaded: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.targetInsightsLoaded;
export const getOldestOccurrence: (state: IngredientProfilerState) => number = (
    state: IngredientProfilerState,
) => state.oldestOccurrence;
export const getNewestOccurrence: (state: IngredientProfilerState) => number = (
    state: IngredientProfilerState,
) => state.newestOccurrence;
export const getShouldShowSourceConcentration: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.shouldShowSourceConcentration;
export const getIsCustomIngredient: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.isCustomIngredient;
export const getBlob: (state: IngredientProfilerState) => Blob = (state: IngredientProfilerState) =>
    state.blob;
export const getCategories: (state: IngredientProfilerState) => string[] = (
    state: IngredientProfilerState,
) => Object.keys(state.categories);
export const getSubcategoriesPerCategory: (
    state: IngredientProfilerState,
) => Record<string, string[]> = (state: IngredientProfilerState) => state.categories;
export const getCategoriesLoading: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.categoriesLoading;
export const getCategoriesLoaded: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.categoriesLoaded;
export const getOverview: (state: IngredientProfilerState) => Overview = (
    state: IngredientProfilerState,
) => state.overview;
export const getOverviewLoading: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.overviewLoading;
export const getOverviewLoaded: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.overviewLoaded;
export const getHealthLabelsStatistics: (
    state: IngredientProfilerState,
) => HealthLabelStatistics[] = (state: IngredientProfilerState) => state.healthLabelsStatistics;
export const getHealthLabelsStatisticsLoading: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.healthLabelsStatisticsLoading;
export const getHealthLabelsStatisticsLoaded: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.healthLabelsStatisticsLoaded;
export const getRelationshipsPerGroup: (
    state: IngredientProfilerState,
) => Record<RelationshipGroup, string[]> = (state: IngredientProfilerState) =>
    state.relationshipsPerGroup;
export const getHealthLabelSummaries: (state: IngredientProfilerState) => HealthLabelSummaries = (
    state: IngredientProfilerState,
) => state.healthLabelSummaries;
export const getHealthLabelTopCompounds: (
    state: IngredientProfilerState,
) => HealthLabelTopCompounds = (state: IngredientProfilerState) => state.healthLabelTopCompounds;
export const getSearchSuggestions: (state: IngredientProfilerState) => string[] = (
    state: IngredientProfilerState,
) => state.searchSuggestions;
export const getSearchSuggestionsLoading: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.searchSuggestionsLoading;
export const getSearchSuggestionsLoaded: (state: IngredientProfilerState) => boolean = (
    state: IngredientProfilerState,
) => state.searchSuggestionsLoaded;
