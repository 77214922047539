import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { BookmarksActionTypes } from './bookmarks-action-types.enum';
import PaginatedBookmarks from './interfaces/paginated-bookmarks.interface';
import Project from '../projects/interfaces/project.interface';
import Bookmark from './interfaces/bookmark.interface';
import BookmarkType from './enums/bookmark.enum';
import BookmarkOrigin from './interfaces/origin.interface';
import BookmarkConfiguration from './interfaces/configuration.interface';
import BookmarkIds from './interfaces/bookmark-ids.interface';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';

export const getBookmarksRequest = createAction(
    BookmarksActionTypes.GET_BOOKMARKS_REQUEST,
    props<{
        projectId: string;
        bookmarkType: BookmarkType;
        filters: ExecutionFilters;
        preferences: UserPreferences;
        studyTypesOrder: string[];
        suppressLoading: boolean;
        pageIndex: number;
        pageSize: number;
        sortingOptions: SortingOptions;
    }>(),
);

export const getBookmarksSuccess = createAction(
    BookmarksActionTypes.GET_BOOKMARKS_SUCCESS,
    props<{
        paginatedBookmarks: PaginatedBookmarks;
        bookmarkType: BookmarkType;
    }>(),
);

export const getBookmarksFailure = createAction(
    BookmarksActionTypes.GET_BOOKMARKS_FAILURE,
    props<{ errorResponse: HttpErrorResponse; bookmarkType: BookmarkType }>(),
);

export const clearBookmarks = createAction(BookmarksActionTypes.CLEAR_BOOKMARKS);

export const createEntityBookmarksRequest = createAction(
    BookmarksActionTypes.CREATE_ENTITY_BOOKMARKS_REQUEST,
    props<{
        projects: Project[];
        origin: BookmarkOrigin;
        configuration: BookmarkConfiguration;
        notes: string;
    }>(),
);

export const createEntityBookmarksSuccess = createAction(
    BookmarksActionTypes.CREATE_ENTITY_BOOKMARKS_SUCCESS,
    props<{ projects: Project[] }>(),
);

export const createEntityBookmarksFailure = createAction(
    BookmarksActionTypes.CREATE_ENTITY_BOOKMARKS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const createAssociationOpenBookmarksRequest = createAction(
    BookmarksActionTypes.CREATE_ASSOCIATION_OPEN_BOOKMARKS_REQUEST,
    props<{
        projects: Project[];
        configuration: BookmarkConfiguration;
        notes: string;
    }>(),
);

export const createAssociationOpenBookmarksSuccess = createAction(
    BookmarksActionTypes.CREATE_ASSOCIATION_OPEN_BOOKMARKS_SUCCESS,
    props<{ projects: Project[] }>(),
);

export const createAssociationOpenBookmarksFailure = createAction(
    BookmarksActionTypes.CREATE_ASSOCIATION_OPEN_BOOKMARKS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const createAssociationClosedBookmarksRequest = createAction(
    BookmarksActionTypes.CREATE_ASSOCIATION_CLOSED_BOOKMARKS_REQUEST,
    props<{
        projects: Project[];
        configuration: BookmarkConfiguration;
        notes: string;
    }>(),
);

export const createAssociationClosedBookmarksSuccess = createAction(
    BookmarksActionTypes.CREATE_ASSOCIATION_CLOSED_BOOKMARKS_SUCCESS,
    props<{ projects: Project[] }>(),
);

export const createAssociationClosedBookmarksFailure = createAction(
    BookmarksActionTypes.CREATE_ASSOCIATION_CLOSED_BOOKMARKS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const createArticleBookmarksRequest = createAction(
    BookmarksActionTypes.CREATE_ARTICLE_BOOKMARKS_REQUEST,
    props<{
        projects: Project[];
        origin: BookmarkOrigin;
        configuration: BookmarkConfiguration;
        notes: string;
    }>(),
);

export const downloadArticleBookmarksRequest = createAction(
    BookmarksActionTypes.DOWNLOAD_ARTICLE_BOOKMARKS_REQUEST,
    props<{ projectId: string; filters: ExecutionFilters }>(),
);

export const downloadArticleBookmarksSuccess = createAction(
    BookmarksActionTypes.DOWNLOAD_ARTICLE_BOOKMARKS_SUCCESS,
    props<{
        blob: Blob;
    }>(),
);

export const downloadArticleBookmarksFailure = createAction(
    BookmarksActionTypes.DOWNLOAD_ARTICLE_BOOKMARKS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const createArticleBookmarksSuccess = createAction(
    BookmarksActionTypes.CREATE_ARTICLE_BOOKMARKS_SUCCESS,
    props<{ projects: Project[] }>(),
);

export const createArticleBookmarksFailure = createAction(
    BookmarksActionTypes.CREATE_ARTICLE_BOOKMARKS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const updateNotesRequest = createAction(
    BookmarksActionTypes.UPDATE_NOTES_REQUEST,
    props<{ bookmarkIds: BookmarkIds; notes: string }>(),
);

export const updateNotesSuccess = createAction(BookmarksActionTypes.UPDATE_NOTES_SUCCESS);

export const updateNotesFailure = createAction(
    BookmarksActionTypes.UPDATE_NOTES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const deleteBookmarkRequest = createAction(
    BookmarksActionTypes.DELETE_BOOKMARK_REQUEST,
    props<{
        projectId: string;
        bookmarkId: string;
        bookmarks: Bookmark[];
        bookmarkType: BookmarkType;
    }>(),
);

export const deleteBookmarkSuccess = createAction(
    BookmarksActionTypes.DELETE_BOOKMARK_SUCCESS,
    props<{
        projectId: string;
        bookmarkId: string;
        bookmarks: Bookmark[];
        bookmarkType: BookmarkType;
    }>(),
);

export const deleteBookmarkFailure = createAction(
    BookmarksActionTypes.DELETE_BOOKMARK_FAILURE,
    props<{ projectId: string; bookmarkId: string; errorResponse: HttpErrorResponse }>(),
);

export const clearNextSuccess = createAction(BookmarksActionTypes.CLEAR_NEXT_SUCCESS);

export const clearNextError = createAction(BookmarksActionTypes.CLEAR_NEXT_ERROR);

export const clearShouldFetch = createAction(BookmarksActionTypes.CLEAR_SHOULD_FETCH);
