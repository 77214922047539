export const primaryColor: (app: string) => string = (app: string) =>
    app === 'mr'
        ? '#0e1a52'
        : app === 'pp' || app === 'pp-ingredient-profiler'
        ? '#353d47'
        : app === 'dmi'
        ? '#098387'
        : app === 'mr-plant'
        ? '#2f4d22'
        : '#363e48';

export const primary900Color: (app: string) => string = (app: string) =>
    app === 'mr'
        ? '#0c1642'
        : app === 'pp' || app === 'pp-ingredient-profiler'
        ? '#21262c'
        : app === 'dmi'
        ? '#096f73'
        : app === 'mr-plant'
        ? '#183909'
        : '#363e48';

export const secondary900Color: (app: string) => string = (app: string) =>
    app === 'mr'
        ? '#096f73'
        : app === 'pp' || app === 'pp-ingredient-profiler'
        ? '#016a84'
        : app === 'dmi'
        ? '#282f38'
        : app === 'mr-plant'
        ? '#381202'
        : '#363e48';

export const concentricCirclesSecondaryColors: (app: string) => string[] = (app: string) =>
    app === 'mr'
        ? ['#e6f3f3', '#cee6e7', '#b5dadb', '#9dcdcf', '#84c1c3', '#6bb5b7']
        : app === 'pp'
        ? ['#e6f2f6', '#cce6ec', '#b3d9e3', '#99cdd9', '#80c0d0', '#4da7bd']
        : app === 'dmi'
        ? ['#f4f5f5', '#eaeaeb', '#d4d5d7', '#bfc1c3', '#a9acaf', '#93979b']
        : app === 'pp-ingredient-profiler'
        ? ['#eaeaeb', '#d4d5d7', '#bfc1c3', '#a9acaf', '#93979b', '#7e8288']
        : app === 'mr-plant'
        ? ['#ebe7e6', '#d7d0cc', '#c3b8b3', '#b0a09a', '#9c8981', '#887167']
        : ['#f4f5f5', '#eaeaeb', '#d4d5d7', '#bfc1c3', '#a9acaf', '#93979b'];

export const secondary400Color: (app: string) => string = (app: string) =>
    app === 'mr'
        ? '#8adbcf'
        : app === 'pp' || app === 'pp-ingredient-profiler'
        ? '#99cdd9'
        : app === 'dmi'
        ? '#a9acaf'
        : app === 'mr-plant'
        ? '#b0a09a'
        : '#363e48';
