/** third-party libraries */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { InsightsService } from '@apps/leap/src/app/shared/services/insights.service';
import { IngredientProfilerParser } from '../../ingredient-profiler/parsers/ingredient-profiler.parser';
import { ProfilerReportParser } from '../../ingredient-profiler/parsers/profiler-report.parser';
import ResultsRestApi from '@leap-common/rest-api-interfaces/results.rest.interface';
import ProfilerSearch from '@apps/leap/src/app/shared/enums/profiler-search.enum';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import PaginatedCompounds from '../../ingredient-profiler/interfaces/paginated-compounds.interface';
import PaginatedCompoundsRestApi from '../../ingredient-profiler/rest-api-interfaces/paginated-compounds.rest.interface';
import PaginatedInsights from '../../ingredient-profiler/interfaces/paginated-insights.interface';
import PaginatedInsightsRestApi from '../../ingredient-profiler/rest-api-interfaces/paginated-insights.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import Target from '../../ingredient-profiler/interfaces/target.interface';
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import TargetRestApi from '../../ingredient-profiler/rest-api-interfaces/target.rest.interface';
import Insight from '../../ingredient-profiler/interfaces/insight.interface';
import Overview from '../../ingredient-profiler/interfaces/overview.interface';
import HealthLabelInfoRestApi from '../../ingredient-profiler/rest-api-interfaces/health-label-info.rest.interface';
import HealthLabelStatistics from '../../ingredient-profiler/interfaces/health-label-statistics.interface';
import HealthLabelStatisticsRestApi from '../../ingredient-profiler/rest-api-interfaces/health-label-statistics.rest.interface';
import HealthLabelSummariesRestApi from '../../ingredient-profiler/rest-api-types/health-label-summaries.rest.type';
import HealthLabelSummaries from '../../ingredient-profiler/interfaces/health-label-summaries.interface';
import HealthLabelTopCompounds from '../../ingredient-profiler/interfaces/health-label-top-compounds.interface';
import HealthLabelTopCompoundsRestApi from '../../ingredient-profiler/rest-api-types/health-label-top-compounds.rest.type';
import RelationshipGroup from '../../ingredient-profiler/enums/relationship-group.enum';

@Injectable()
export class DairyProfilerService {
    constructor(
        private http: HttpClient,
        private ingredientProfilerParser: IngredientProfilerParser,
        private profilerReportParser: ProfilerReportParser,
        private insightsService: InsightsService,
    ) {}

    getSearchSuggestions(query: string, activeSearch: ProfilerSearch): Observable<string[]> {
        const searchPath: string =
            activeSearch === ProfilerSearch.compounds ||
            activeSearch === ProfilerSearch.targetInsights
                ? 'components'
                : ProfilerSearch.insights
                ? 'targets'
                : null;

        return this.http
            .post(`${environment.dairyProfilerServerUrl}/${searchPath}/search`, {
                searchString: query,
            })
            .pipe(map(({ results }: ResultsRestApi<string>) => results));
    }

    getCompounds(
        filters: ExecutionFilters,
        pageSize: number,
        pageIndex: number,
        preferences?: UserPreferences,
    ): Observable<PaginatedCompounds> {
        return this.http
            .post(`${environment.dairyProfilerServerUrl}/components`, {
                filters,
                pageSize,
                pageIndex,
                preferences,
            })
            .pipe(
                map((paginatedResults: PaginatedCompoundsRestApi) =>
                    this.ingredientProfilerParser.parsePaginatedCompounds(paginatedResults),
                ),
            );
    }

    getInsights(
        compoundId: string,
        filters: ExecutionFilters,
        pageSize: number,
        pageIndex: number,
        preferences: UserPreferences,
        sortingOptions?: SortingOptions,
    ): Observable<PaginatedInsights> {
        return this.http
            .post(`${environment.dairyProfilerServerUrl}/insights`, {
                sourceUid: compoundId,
                filters,
                pageSize,
                pageIndex,
                preferences,
                ...(Boolean(sortingOptions) && {
                    sortingBy: sortingOptions.field,
                    sortingOrder: sortingOptions.order,
                }),
            })
            .pipe(
                map((paginatedResults: PaginatedInsightsRestApi) =>
                    this.ingredientProfilerParser.parsePaginatedInsights(paginatedResults),
                ),
            );
    }

    getDairyProductInsights(
        filters: ExecutionFilters,
        pageSize: number,
        pageIndex: number,
        preferences: UserPreferences,
    ): Observable<PaginatedResults<Target>> {
        return this.http
            .post(`${environment.dairyProfilerServerUrl}/targets`, {
                filters,
                pageSize,
                pageIndex,
                preferences,
            })
            .pipe(
                map((paginatedResults: PaginatedResultsRestApi<TargetRestApi>) =>
                    this.ingredientProfilerParser.parsePaginatedTargets(paginatedResults),
                ),
            );
    }

    getTargetInsights(
        targetId: string,
        filters: ExecutionFilters,
        pageSize: number,
        pageIndex: number,
        preferences: UserPreferences,
    ): Observable<PaginatedInsights> {
        return this.http
            .post(`${environment.dairyProfilerServerUrl}/insights`, {
                targetUid: targetId,
                filters,
                pageSize,
                pageIndex,
                preferences,
            })
            .pipe(
                map((paginatedResults: PaginatedInsightsRestApi) => {
                    const parsedPaginatedInsights: PaginatedInsights =
                        this.ingredientProfilerParser.parsePaginatedInsights(paginatedResults);

                    const reversedInsights: Insight[] = parsedPaginatedInsights.results.map(
                        (insight: Insight) =>
                            this.insightsService.reverseInsightProperties(insight),
                    );

                    return {
                        ...parsedPaginatedInsights,
                        results: reversedInsights,
                    };
                }),
            );
    }

    downloadInsights(filters: ExecutionFilters, preferences?: UserPreferences): Observable<Blob> {
        return this.http.post(
            `${environment.dairyProfilerServerUrl}/insights/download`,
            {
                filters,
                preferences,
            },
            {
                headers: new HttpHeaders({
                    Accept: 'text/csv',
                }),
                responseType: 'blob',
            },
        );
    }

    getOverview(
        countBy: string,
        filters: ExecutionFilters,
        preferences?: UserPreferences,
    ): Observable<Overview> {
        return this.http
            .post(`${environment.dairyProfilerServerUrl}/report?section=Overview`, {
                countBy,
                filters,
                preferences,
            })
            .pipe(
                map(({ healthAreas }: { healthAreas: HealthLabelInfoRestApi[] }) =>
                    this.profilerReportParser.parseOverview(healthAreas),
                ),
            );
    }

    getHealthLabelsStatistics(
        filters: ExecutionFilters,
        preferences?: UserPreferences,
    ): Observable<{
        healthLabelsStatistics: HealthLabelStatistics[];
        relationshipsPerGroup: Record<RelationshipGroup, string[]>;
    }> {
        return this.http
            .post(
                `${environment.dairyProfilerServerUrl}/report?section=Health area&subsection=statistics`,
                {
                    filters,
                    preferences,
                },
            )
            .pipe(
                map(
                    ({
                        healthAreas,
                        relationshipGroups,
                    }: {
                        healthAreas: HealthLabelStatisticsRestApi[];
                        relationshipGroups: Record<RelationshipGroup, string[]>;
                    }) => ({
                        healthLabelsStatistics:
                            this.profilerReportParser.parseHealthLabelsStatistics(healthAreas),
                        relationshipsPerGroup: relationshipGroups,
                    }),
                ),
            );
    }

    getHealthLabelSummaries(
        healthLabel: string,
        filters: ExecutionFilters,
        preferences?: UserPreferences,
    ): Observable<HealthLabelSummaries> {
        return this.http
            .post(
                `${environment.dairyProfilerServerUrl}/report?section=Health area&subsection=summary`,
                {
                    healthArea: healthLabel,
                    filters,
                    preferences,
                },
            )
            .pipe(
                map(({ summaries }: { summaries: HealthLabelSummariesRestApi }) =>
                    this.profilerReportParser.parseHealthLabelSummaries(healthLabel, summaries),
                ),
            );
    }

    getHealthLabelTopCompounds(
        healthLabel: string,
        filters: ExecutionFilters,
        preferences?: UserPreferences,
    ): Observable<HealthLabelTopCompounds> {
        return this.http
            .post(
                `${environment.dairyProfilerServerUrl}/report?section=Health area&subsection=topAssociations`,
                {
                    healthArea: healthLabel,
                    filters,
                    preferences,
                },
            )
            .pipe(
                map(({ topMolecules }: { topMolecules: HealthLabelTopCompoundsRestApi }) =>
                    this.profilerReportParser.parseHealthLabelTopCompounds(
                        healthLabel,
                        topMolecules,
                    ),
                ),
            );
    }
}
