import { createSelector } from '@ngrx/store';
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import * as fromArticles from './articles.reducer';
import { ArticlesState } from './articles-state.interface';
import Range from '@leap-common/types/range.type';

export const getArticlesState = createSelector(getDataState, (state: DataState) => state.articles);

export const getArticlesInfo = createSelector(getArticlesState, fromArticles.getArticlesInfo);
export const getTotal = createSelector(getArticlesState, fromArticles.getTotal);
export const getFilteredTotal = createSelector(getArticlesState, fromArticles.getFilteredTotal);
export const getFilterCounts = createSelector(getArticlesState, fromArticles.getFilterCounts);
export const getDateRange = createSelector(getArticlesState, fromArticles.getDateRange);
export const getSelectedArticleInfo = createSelector(
    getArticlesState,
    fromArticles.getSelectedArticleInfo,
);
export const getArticles = createSelector(getArticlesState, fromArticles.getArticlesEntities);
export const getActiveArticles = createSelector(
    getArticlesState,
    (state: ArticlesState, props: { dateRange?: Range }) =>
        fromArticles.getActiveArticles(state, props.dateRange),
);
export const getTermArticles = createSelector(getArticlesState, fromArticles.getTermArticles);
export const getTermArticlesLoading = createSelector(
    getArticlesState,
    fromArticles.getTermArticlesLoading,
);
export const getTermArticlesLoaded = createSelector(
    getArticlesState,
    fromArticles.getTermArticlesLoaded,
);
export const getTermArticlesFilteredTotal = createSelector(
    getArticlesState,
    fromArticles.getTermArticlesFilteredTotal,
);
export const getErrors = createSelector(getArticlesState, fromArticles.getErrors);
export const getLoading = createSelector(getArticlesState, fromArticles.getLoading);
export const getLoaded = createSelector(getArticlesState, fromArticles.getLoaded);
export const getBlob = createSelector(getArticlesState, fromArticles.getBlob);
export const getTermArticlesBlob = createSelector(
    getArticlesState,
    fromArticles.getTermArticlesBlob,
);
export const getFullArticleBlob = createSelector(getArticlesState, fromArticles.getFullArticleBlob);
export const getBibTex = createSelector(getArticlesState, fromArticles.getBibTex);
export const getBibTexLoading = createSelector(getArticlesState, fromArticles.getBibTexLoading);
export const getBibTexLoaded = createSelector(getArticlesState, fromArticles.getBibTexLoaded);
export const getBibTexBlob = createSelector(getArticlesState, fromArticles.getBibTexBlob);
