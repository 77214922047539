import { Component, Input } from '@angular/core';
import { SUBTITLE } from '../../../constants/landing';

@Component({
    selector: 'app-header',
    templateUrl: 'header.view.component.html',
    styleUrls: ['header.view.component.scss'],
})
export class HeaderViewComponent {
    @Input() organization: string;

    readonly subtitle: string = SUBTITLE;

    constructor() {}

    setLogo(): Record<string, string> {
        const logo: Record<string, string> = {
            'background-image': `url('assets/graphics/${this.organization}/login-logo.svg')`,
            'background-repeat': 'no-repeat',
            'background-size': 'contain',
            'background-position': 'center',
            height:
                this.organization === 'mr' || this.organization === 'mr-plant'
                    ? '70px'
                    : this.organization === 'pp'
                    ? '75px'
                    : this.organization === 'dmi'
                    ? '95px'
                    : '90px',
        };

        return logo;
    }
}
