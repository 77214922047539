import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/leap/environment';

/** Constants */
import { FEATURE_CARD_ITEMS } from '../../../constants/landing';

/** Interfaces */
import MenuItem from '@leap-libs/main-menu/src/lib/menu-item.interface';

@Component({
    selector: 'app-landing',
    templateUrl: 'landing.container.component.html',
    styleUrls: ['landing.container.component.scss'],
})
export class LandingContainerComponent {
    @HostBinding('class') classes = 'content-wrapper';

    organization: string = environment.organization;

    readonly featureCardItems: MenuItem[] = FEATURE_CARD_ITEMS;

    constructor(private router: Router) {}

    setBackground(): Record<string, string> {
        return {
            'background-image': `url('assets/graphics/${this.organization}/full-background.png')`,
            'background-repeat': 'no-repeat',
            'background-size': 'cover',
            'background-position': 'right',
        };
    }

    onFeatureCardClicked(path: string): void {
        this.router.navigate([path ? path : '/']);
    }
}
