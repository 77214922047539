import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/leap/environment';
import { Observable, Subscription } from 'rxjs';
import { NgbConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import buildJSON from '../../assets/build.json';

/** Services - Facades */
import { AppService } from './app.service';
import { AnalyticsService } from './analytics/services/analytics.service';
import { AlertsFacade } from '@leap-store/core/src/lib/ui/alerts/alerts.facade';

/** Interfaces */
import AlertEvent from '@leap-store/core/src/lib/ui/alerts/interfaces/alert-event.interface';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    // organization
    organization: string = environment.organization;
    title: HTMLLinkElement = document.querySelector('#title');
    favicon: HTMLLinkElement = document.querySelector('#favicon');

    // version
    currentVersion: string;
    latestVersion: string;
    latestVersionSubscription: Subscription;
    versionCheckInterval: ReturnType<typeof setInterval>;

    // alert
    alertEvents$: Observable<AlertEvent[]> = this.alertsFacade.alertEvents$;
    alertEvents: AlertEvent[];

    constructor(
        private appService: AppService,
        private alertsFacade: AlertsFacade,
        private ngbConfig: NgbConfig,
        private ngbTooltipConfig: NgbTooltipConfig,
        private analyticsService: AnalyticsService,
    ) {
        this.title.innerHTML = environment.product;
        this.favicon.href = `../../assets/graphics/${this.organization}/favicon.svg`;
        this.analyticsService.initializeProviders();
    }

    ngOnInit(): void {
        this.handleAlertEvents();
        this.initializeNgBootstrap();
        this.initializeCurrentVersion();
        this.initializeVersionCheckInterval();
    }

    initializeNgBootstrap(): void {
        this.ngbConfig.animation = false;
        // NgbTooltip global configuration
        this.ngbTooltipConfig.placement = 'auto';
        this.ngbTooltipConfig.container = 'body';
    }

    /** Initializes app current version based on the build.json asset */
    initializeCurrentVersion(): void {
        this.currentVersion = buildJSON.version;
    }

    /** Subscribes to alertEvents$ and assigns value to alertEvents */
    handleAlertEvents(): void {
        this.alertEvents$.subscribe((alertEvents: AlertEvent[]) => {
            this.alertEvents = alertEvents;
        });
    }

    /**
     * Clears existing alerts when user clicks anywhere in the rest of the page
     */
    onPageClicked(): void {
        if (this.alertEvents.length > 0) {
            // call component's closeAlert() for each alertEvent
            this.alertEvents.forEach((alertEvent: AlertEvent) => this.closeAlert(alertEvent));
        }
    }

    /** Calls the closeAlert() */
    onAlertToBeClosed(alert: AlertEvent): void {
        this.closeAlert(alert, true);
    }

    /**
     * Calls the alertsFacade.removeAlertEvent() which executes the alertEvent onClose callback
     */
    closeAlert(alert: AlertEvent, shouldRemovePersistent: boolean = false): void {
        this.alertsFacade.removeAlertEvent(alert.id, shouldRemovePersistent);
    }

    initializeVersionCheckInterval(): void {
        this.versionCheckInterval = setInterval(() => {
            this.handleVersionUpdate();
        }, 900000); // check for updates every 15'
    }

    clearVersionCheckInterval(): void {
        clearInterval(this.versionCheckInterval);
    }

    handleVersionUpdate(): void {
        this.latestVersionSubscription?.unsubscribe();

        this.latestVersionSubscription = this.appService
            .getLatestVersion()
            .subscribe((latestVersion: string) => {
                this.latestVersion = latestVersion;
                if (this.latestVersion !== this.currentVersion) {
                    this.clearVersionCheckInterval(); // Stop the interval when displaying the prompt
                    this.displayReloadPrompt();
                }
            });
    }

    displayReloadPrompt(): void {
        const isReloadConfirmed: boolean = confirm(
            'Reload to get the updated version of the application. It will take only a few seconds.',
        );

        if (isReloadConfirmed) {
            window.location.reload();
        } else {
            this.initializeVersionCheckInterval(); // Start the interval again if the user cancels the prompt
        }
    }
}
