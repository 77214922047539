import { Component, Input } from '@angular/core';

@Component({
    selector: 'lib-footer',
    templateUrl: 'footer.component.html',
    styleUrls: ['footer.component.scss'],
})
export class FooterComponent {
    @Input() currentYear: number;
    @Input() isCopyrightDisplayed: boolean = true;
    @Input() isConfidentialDisplayed: boolean = false;

    constructor() {}
}
