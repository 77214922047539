import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { SuggestionsActionTypes } from './suggestions-action-types.enum';
import Suggestion from './interfaces/suggestion.interface';

export const getSuggestionsRequest = createAction(
    SuggestionsActionTypes.GET_SUGGESTIONS_REQUEST,
    props<{
        id: string;
        query: string;
        limit: number;
    }>(),
);

export const getSuggestionsSuccess = createAction(
    SuggestionsActionTypes.GET_SUGGESTIONS_SUCCESS,
    props<{
        id: string;
        suggestions: Suggestion[];
    }>(),
);

export const getSuggestionsFailure = createAction(
    SuggestionsActionTypes.GET_SUGGESTIONS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearNextError = createAction(SuggestionsActionTypes.CLEAR_NEXT_ERROR);
