import { Injectable } from '@angular/core';
import {
    FormGroup,
    AbstractControl,
    ValidatorFn,
    AsyncValidatorFn,
    ValidationErrors,
} from '@angular/forms';
import { Observable, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import Suggestion from '@leap-store/core/src/lib/data/discovery/suggestions/interfaces/suggestion.interface';

@Injectable()
export class FormValidationService {
    constructor() {}

    /** Validator that requires the controls' values to match */
    fieldsMatchValidator(
        controlName: string,
        matchingControlName: string,
        error: ValidationErrors,
    ): ValidatorFn {
        return (group: FormGroup): ValidationErrors => {
            const controlInput: AbstractControl = group.controls?.[controlName];
            const matchingControlInput: AbstractControl = group.controls?.[matchingControlName];

            // return if another validator has already found an error on the matchingControlName
            if (matchingControlInput.errors && !matchingControlInput.errors.fieldsMismatch) {
                return;
            }

            // set error on matchingControlName if validation fails
            controlInput.value !== matchingControlInput.value
                ? matchingControlInput.setErrors(error)
                : matchingControlInput.setErrors(null);

            return;
        };
    }

    /** Validator that requires the control's value to match the regex pattern */
    patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (controlInput: AbstractControl): Record<string, ValidationErrors> => {
            // if control is empty return
            if (!controlInput.value) {
                return;
            }

            // set error if validation fails
            return regex.test(controlInput.value) ? null : error;
        };
    }

    /** Validator that requires the control have a non-empty value */
    requiredValidator(error: ValidationErrors): ValidatorFn {
        return (controlInput: AbstractControl): Record<string, ValidationErrors> =>
            // set error if validation fails
            controlInput.value ? null : error;
    }

    /**
     * Validator that requires the control's value to be actual text and not only whitespaces.
     */
    whitespaceValidator(error: ValidationErrors): ValidatorFn {
        return (controlInput: AbstractControl): Record<string, ValidationErrors> => {
            const isWhitespace: boolean = (controlInput.value || '').trim().length === 0;
            return isWhitespace ? error : null;
        };
    }

    /**
     * Validator that requires the length of the control's value to be greater than or equal
     * to the provided minimum length
     */
    minLengthValidator(minLength: number, error: ValidationErrors): ValidatorFn {
        return (controlInput: AbstractControl): Record<string, ValidationErrors> => {
            // if control is empty return
            if (!controlInput.value) {
                return;
            }

            const length: number = controlInput.value ? controlInput.value.length : 0;

            // set error if validation fails
            return length < minLength ? error : null;
        };
    }

    /**
     * Validator that requires the length of the control's value to be less than or equal
     * to the provided maximum length
     */
    maxLengthValidator(maxLength: number, error: ValidationErrors): ValidatorFn {
        return (controlInput: AbstractControl): Record<string, ValidationErrors> => {
            // if control is empty return
            if (!controlInput.value) {
                return;
            }

            const length: number = controlInput.value ? controlInput.value.length : 0;

            // set error if validation fails
            return length > maxLength ? error : null;
        };
    }

    /** Validator that requires the form value to have been selected from an array of options */
    valueSelectedValidator(array$: Observable<Suggestion[]>, error: ValidationErrors): ValidatorFn {
        let optionsArray: Suggestion[] = [];

        array$.subscribe((array: Suggestion[]) => {
            optionsArray = array;
        });

        return (controlInput: AbstractControl): Record<string, ValidationErrors> => {
            const selectedValue = controlInput.value;

            if (!selectedValue) {
                return;
            }

            const selectedOption: Suggestion[] = optionsArray.filter(
                (option: Suggestion) => option.term && option.term === selectedValue.term,
            );

            return selectedOption.length > 0 ? null : error;
        };
    }

    /**
     * Validator that returns an error when the value of an observable is true.
     * Can be used for server-side validation.
     */
    asyncValidator(observable$: Observable<boolean>, error: ValidationErrors): AsyncValidatorFn {
        return () =>
            observable$.pipe(
                // Make the observable finite as required for async validators
                takeUntil(timer(0)),
                map((observable: boolean) => (observable ? error : null)),
            );
    }
}
