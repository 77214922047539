/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { JwtParser } from '../parsers/jwt.parser';

/** Interfaces */
import LoginUser from '../interfaces/login-user.interface';
import Jwt from '../interfaces/jwt.interface';
import JwtRestApi from '../rest-api-interfaces/jwt.rest.interface';

@Injectable()
export class LoginService {
    constructor(private http: HttpClient, private jwtParser: JwtParser) {}

    /**
     * Posts the login user to the server and returns an Observable of Jwt
     */
    login(user: LoginUser): Observable<Jwt> {
        const app: string = environment.app;

        return this.http
            .post(
                `${environment.serverUrl}/authentication/login/?refreshToken=True&app=${app}`,
                user,
            )
            .pipe(map((jwt: JwtRestApi) => this.jwtParser.parseJwt(jwt)));
    }

    /**
     *  Posts the refreshToken and returns an Observable of Jwt
     */
    refreshLogin(refreshToken: string): Observable<Jwt> {
        const app: string = environment.app;

        return this.http
            .post(`${environment.serverUrl}/authentication/refresh/?app=${app}`, {
                refreshToken,
            })
            .pipe(map((jwt: JwtRestApi) => this.jwtParser.parseJwt(jwt)));
    }

    acceptTermsOfUse(): Observable<Jwt> {
        const app: string = environment.app;

        return this.http
            .post(`${environment.serverUrl}/terms-of-service/accept/?app=${app}`, {})
            .pipe(map((jwt: JwtRestApi) => this.jwtParser.parseJwt(jwt)));
    }
}
