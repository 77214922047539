/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { ProjectsService } from './services/projects.service';
import * as projectsActions from './projects.actions';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import Project from './interfaces/project.interface';

@Injectable()
export class ProjectsEffects {
    constructor(private actions$: Actions, private projectsService: ProjectsService) {}

    getProjects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.getProjectsRequest),
            switchMap(
                ({
                    pageIndex,
                    pageSize,
                    sortDirection,
                    sortColumn,
                    typeFilter,
                    statusFilter,
                    searchFilter,
                }) =>
                    this.projectsService
                        .getProjects(
                            pageIndex,
                            pageSize,
                            sortDirection,
                            sortColumn,
                            typeFilter,
                            statusFilter,
                            searchFilter,
                        )
                        .pipe(
                            map(
                                ({
                                    paginatedProjects,
                                }: {
                                    paginatedProjects: PaginatedResults<Project>;
                                }) =>
                                    projectsActions.getProjectsSuccess({
                                        paginatedProjects,
                                        sortDirection,
                                        sortColumn,
                                    }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(projectsActions.getProjectsFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.getProjectRequest),
            switchMap(({ id }) =>
                this.projectsService.getProject(id).pipe(
                    map((project: Project) => projectsActions.getProjectSuccess({ project })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(projectsActions.getProjectFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getProjectsWithBookmark$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.getProjectsWithBookmarkRequest),
            switchMap(({ configuration }) =>
                this.projectsService.getProjectsWithBookmark(configuration).pipe(
                    map(({ paginatedProjects }: { paginatedProjects: Project[] }) =>
                        projectsActions.getProjectsWithBookmarkSuccess({
                            projects: paginatedProjects,
                        }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(projectsActions.getProjectsWithBookmarkFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getProjectsWithoutBookmark$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.getProjectsWithoutBookmarkRequest),
            switchMap(({ configuration, search, pageIndex, pageSize }) =>
                this.projectsService
                    .getProjectsWithoutBookmark(configuration, search, pageIndex, pageSize)
                    .pipe(
                        map(
                            ({
                                paginatedProjects,
                            }: {
                                paginatedProjects: PaginatedResults<Project>;
                            }) =>
                                projectsActions.getProjectsWithoutBookmarkSuccess({
                                    paginatedProjects,
                                }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(
                                projectsActions.getProjectsWithoutBookmarkFailure({
                                    errorResponse,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    createProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.createProjectRequest),
            switchMap(({ name, suppressAlert }) =>
                this.projectsService.createProject(name).pipe(
                    map((project: Project) =>
                        projectsActions.createProjectSuccess({ project, suppressAlert }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(projectsActions.createProjectFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    cloneProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.cloneProjectRequest),
            switchMap(({ id, originalProjectName }) =>
                this.projectsService.cloneProject(id, originalProjectName).pipe(
                    map((cloneData: { project: Project; originalProjectName: string }) =>
                        projectsActions.cloneProjectSuccess(cloneData),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(projectsActions.cloneProjectFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    updateProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.updateProjectRequest),
            switchMap(({ id, project, successMessage }) =>
                this.projectsService.updateProject(id, project).pipe(
                    map((updatedProject: Project) =>
                        projectsActions.updateProjectSuccess({
                            project: updatedProject,
                            successMessage,
                        }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(projectsActions.updateProjectFailure({ id, errorResponse })),
                    ),
                ),
            ),
        ),
    );

    toggleFavoriteProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.toggleFavoriteProjectRequest),
            switchMap(({ id, favorite }) =>
                this.projectsService.toggleFavoriteProject(id, favorite).pipe(
                    map((project: Project) =>
                        projectsActions.toggleFavoriteProjectSuccess({ project }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(projectsActions.toggleFavoriteProjectFailure({ id, errorResponse })),
                    ),
                ),
            ),
        ),
    );

    deleteProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.deleteProjectRequest),
            switchMap(({ id, name }) =>
                this.projectsService.deleteProject(id).pipe(
                    map(() => projectsActions.deleteProjectSuccess({ id, name })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(projectsActions.deleteProjectFailure({ id, errorResponse })),
                    ),
                ),
            ),
        ),
    );

    downloadProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.downloadProjectRequest),
            switchMap(({ id, preferences }) =>
                this.projectsService.downloadProject(id, preferences).pipe(
                    map((blob: Blob) => projectsActions.downloadProjectSuccess({ blob })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(projectsActions.downloadProjectFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
