/** third-party imports */
import { Injectable } from '@angular/core';
import { isEmpty, sum } from 'lodash';

/** Constants */
import { FOOD_CATEGORY } from '@apps/leap/src/app/shared/constants/discovery';

/** Parsers */
import { InsightParser } from '@apps/leap/src/app/shared/parsers/insight.parser';
import { DiscoveryParser } from '@apps/leap/src/app/shared/parsers/discovery.parser';
import { OpenDiscoveryParser } from '../../discovery/open/parsers/open-discovery.parser';
import { ClosedDiscoveryParser } from '../../discovery/closed/parsers/closed-discovery.parser';

/** custom imports */
import OpenDiscoveryInsightRestApi from '../../discovery/open/rest-api-interfaces/insight.rest.interface';
import ClosedDiscoveryInsightRestApi from '../../discovery/closed/rest-api-interfaces/insight.rest.interface';
import AssociationType from '@apps/leap/src/app/shared/enums/association-type.enum';
import StatisticsRestApi from '../rest-api-interfaces/statistics.rest.interface';
import Statistics from '../interfaces/statistics.interface';
import TopResultsRestApi from '../rest-api-types/top-results.rest.type';
import TopResultRestApi from '../rest-api-interfaces/top-result.rest.interface';
import TopResults from '../types/top-results.type';
import ReferenceSummarizationPerCategory from '../interfaces/reference-summarization-per-category.interface';
import ReferenceRestApi from '../rest-api-interfaces/reference.rest.interface';
import Reference from '../interfaces/reference.interface';
import AuthorRestApi from '../rest-api-interfaces/author.rest.interface';
import Author from '../interfaces/author.interface';
import JournalRestApi from '../rest-api-interfaces/journal.rest.interface';
import Journal from '../interfaces/journal.interface';
import ResultsPerAssociationTypeRestApi from '../rest-api-types/results-per-association-type.rest.type';
import ResultsPerAssociationType from '../interfaces/results-per-association-type.interface';
import AssociationOriginRestApi from '@apps/leap/src/app/shared/enums/association-origin.rest.enum';
import ResultsPerAssociationOriginRestApi from '../rest-api-types/results-per-association-origin.rest.type';
import ResultsPerAssociationOrigin from '../interfaces/results-per-association-origin.interface';
import CoOccurrenceRestApi from '../rest-api-interfaces/co-occurrence.rest.interface';
import CoOccurrence from '../interfaces/co-occurrence.interface';
import PublicationDatesRestApi from '../rest-api-interfaces/publication-dates.rest.interface';
import PublicationDates from '../interfaces/publication-dates.interface';
import BackgroundRestApi from '../rest-api-interfaces/background.rest.interface';
import Background from '../interfaces/background.interface';
import StatisticsItem from '../interfaces/statistics-item.interface';
import ReportPayloadRestApi from '../rest-api-interfaces/report-payload.rest.interface';
import ReportPayload from '../interfaces/report-payload.interface';
import Discovery from '@apps/leap/src/app/shared/enums/discovery.enum';
import FoodType from '../enums/food-type.enum';

@Injectable()
export class ReportParser {
    constructor(
        private insightParser: InsightParser,
        private discoveryParser: DiscoveryParser,
        private openDiscoveryParser: OpenDiscoveryParser,
        private closedDiscoveryParser: ClosedDiscoveryParser,
    ) {}

    parseBackground(background: BackgroundRestApi): Background {
        return {
            overview: background.overview,
            referencesSummary: background.references_synopsis,
        };
    }

    parseStatistics(statistics: StatisticsRestApi): Statistics {
        return statistics
            ? {
                  description: statistics.description,
                  resultsPerAssociationType: this.parseResultsPerAssociationType(
                      statistics.associationTypes,
                  ),
                  resultsPerAssociationOrigin: this.parseResultsPerAssociationOrigin(
                      statistics.associationOrigins,
                  ),
                  coOccurrence: this.parseCoOccurrence(statistics.max_co_occurrence),
                  publicationDates: this.parsePublicationDates(statistics.publication),
                  associationsPerCategory: this.parseAssociationsPerCategory(
                      statistics.associationsPerCategorySubcategory,
                  ),
                  associationsPerSubcategory: this.parseAssociationsPerSubcategory(
                      statistics.associationsPerCategorySubcategory,
                  ),
              }
            : null;
    }

    parseResultsPerAssociationType(
        associationTypes: ResultsPerAssociationTypeRestApi,
    ): ResultsPerAssociationType[] {
        return Object.entries(associationTypes).map(([key, value]) => ({
            text: key as unknown as [keyof typeof AssociationType],
            count: value,
        }));
    }

    parseResultsPerAssociationOrigin(
        associationOrigins: ResultsPerAssociationOriginRestApi,
    ): ResultsPerAssociationOrigin[] {
        return Object.entries(associationOrigins).map(([key, value]) => ({
            text: this.insightParser.parseAssociationOrigin(key as AssociationOriginRestApi),
            count: value,
        }));
    }

    parseCoOccurrence(statisticsCoOccurrence: CoOccurrenceRestApi): CoOccurrence {
        return statisticsCoOccurrence
            ? {
                  targetId: statisticsCoOccurrence.targetTerm,
                  targetName: statisticsCoOccurrence.targetName,
                  count: statisticsCoOccurrence.co_occurrences,
              }
            : undefined;
    }

    parsePublicationDates(publicationDates: PublicationDatesRestApi): PublicationDates {
        return publicationDates
            ? {
                  oldest: publicationDates.oldest,
                  newest: publicationDates.newest,
              }
            : undefined;
    }

    parseAssociationsPerCategory(
        associationsPerSubcategory: Record<string, Record<string, number>>,
    ): Record<string, number> {
        return Object.entries(associationsPerSubcategory).reduce(
            (accumulator: Record<string, number>, [category, countPerSubcategory]) => {
                accumulator[category] = sum(Object.values(countPerSubcategory));
                return accumulator;
            },
            {},
        );
    }

    parseAssociationsPerSubcategory(
        associationsPerSubcategory: Record<string, Record<string, number>>,
    ): Record<string, StatisticsItem[]>[] {
        return Object.entries(associationsPerSubcategory).map(
            ([category, countPerSubcategory]) => ({
                [category]: Object.entries(countPerSubcategory).map(([subcategory, count]) => ({
                    name: subcategory,
                    value: count,
                })),
            }),
        );
    }

    parseTopResults(
        topResults: TopResultsRestApi,
        discovery: Discovery,
        studyTypesOrder: string[],
    ): TopResults {
        return Object.keys(topResults).reduce((accumulator: TopResults, category: string) => {
            accumulator[category] = {
                [AssociationType.novel]: null,
                [AssociationType.known]: null,
                [FoodType.general]: null,
                [FoodType.specific]: null,
            };

            if (category === FOOD_CATEGORY) {
                const foodTopResults: Record<FoodType, { known: TopResultRestApi[] }> = topResults[
                    category
                ] as Record<FoodType, { known: TopResultRestApi[] }>;

                Object.values(FoodType).forEach((name: FoodType): void => {
                    const knownResults: TopResultRestApi[] = foodTopResults[name]?.known;

                    if (!knownResults) {
                        return;
                    }

                    if (discovery === Discovery.open) {
                        accumulator[category][name] = knownResults.map(
                            (insight: TopResultRestApi) => ({
                                referenceIndex: insight.referenceIndex,
                                description: insight.description,
                                ...this.openDiscoveryParser.parseInsight(
                                    insight as OpenDiscoveryInsightRestApi,
                                ),
                            }),
                        );
                    } else if (discovery === Discovery.closed) {
                        accumulator[category][name] = knownResults.map(
                            (insight: TopResultRestApi) => ({
                                referenceIndex: insight.referenceIndex,
                                description: insight.description,
                                ...this.closedDiscoveryParser.parseInsight(
                                    insight as ClosedDiscoveryInsightRestApi,
                                    studyTypesOrder,
                                ),
                            }),
                        );
                    }
                });
            } else {
                const categoryTopResults: Record<keyof typeof AssociationType, TopResultRestApi[]> =
                    topResults[category] as Record<
                        keyof typeof AssociationType,
                        TopResultRestApi[]
                    >;
                Object.entries(AssociationType).forEach(
                    ([type, name]: [keyof typeof AssociationType, AssociationType]): void => {
                        if (!categoryTopResults[type]) {
                            return;
                        }

                        if (discovery === Discovery.open) {
                            accumulator[category][name] = categoryTopResults[type].map(
                                (insight: TopResultRestApi) => ({
                                    referenceIndex: insight.referenceIndex,
                                    description: insight.description,
                                    ...this.openDiscoveryParser.parseInsight(
                                        insight as OpenDiscoveryInsightRestApi,
                                    ),
                                }),
                            );
                        } else if (discovery === Discovery.closed) {
                            accumulator[category][name] = categoryTopResults[type].map(
                                (insight: TopResultRestApi) => ({
                                    referenceIndex: insight.referenceIndex,
                                    description: insight.description,
                                    ...this.closedDiscoveryParser.parseInsight(
                                        insight as ClosedDiscoveryInsightRestApi,
                                        studyTypesOrder,
                                    ),
                                }),
                            );
                        }
                    },
                );
            }
            return accumulator;
        }, {});
    }

    parseReferencesSummarization(
        summarization: Record<string, string>,
    ): ReferenceSummarizationPerCategory[] {
        return Object.entries(summarization).map(([key, value]) => ({
            category: key,
            text: value,
        }));
    }

    parseReferences(references: ReferenceRestApi[]): Reference[] {
        return (
            references?.map((reference: ReferenceRestApi) => this.parseReference(reference)) || []
        );
    }

    parseReference(reference: ReferenceRestApi): Reference {
        return {
            id: reference.referenceId ?? reference.id,
            index: reference.index,
            title: reference.title,
            url: reference.url,
            pages: reference.pages,
            publicationDate: reference.publication,
            authors: !isEmpty(reference.authors) && this.parseAuthors(reference.authors),
            journal: !isEmpty(reference.journal) && this.parseJournal(reference.journal),
            doi: reference.doi,
        };
    }

    parseAuthors(authors: AuthorRestApi[]): Author[] {
        return authors?.map((author: AuthorRestApi) => this.parseAuthor(author));
    }

    parseAuthor(author: AuthorRestApi): Author {
        return {
            firstName: author.forename,
            lastName: author.lastname,
        };
    }

    parseJournal(journal: JournalRestApi): Journal {
        return {
            title: journal.title,
            issue: journal.issue,
            issueVolume: journal.issue_volume,
            issueDay: journal.issue_day,
            issueMonth: journal.issue_month,
            issueYear: journal.issue_year,
        };
    }

    serializePayload(payload: ReportPayload): ReportPayloadRestApi {
        return {
            type: payload.type,
            parameters: this.discoveryParser.serializeDiscoveryExecutionParameters(
                payload.parameters,
            ),
            filters: payload.filters,
            preferences: payload.preferences,
        };
    }
}
