/** third-party imports */
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

/** custom imports */
import { CompoundReportService } from './services/compound-report.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as actions from './compound-report.actions';
import Overview from './interfaces/overview.interface';
import RelationshipGroup from '../ingredient-profiler/enums/relationship-group.enum';
import Effect from '../ingredient-profiler/enums/effect.enum';
import EffectSummaries from './interfaces/effect-summaries.interface';
import RelationshipGroups from './interfaces/relationship-groups.interface';
import RelationshipGroupSummaries from './interfaces/relationship-group-summaries.interface';
import HealthLabel from './interfaces/health-label.interface';
import StudyTypes from './interfaces/study-types.interface';
import StudyType from './interfaces/study-type.interface';
import Summary from '../report/interfaces/summary.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';

@Injectable()
export class CompoundReportEffects {
    constructor(private actions$: Actions, private compoundReportService: CompoundReportService) {}

    getOverview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getOverviewRequest),
            switchMap(
                ({
                    compoundId,
                    ingredientName,
                    filters,
                    areEffectsEnabled,
                }: {
                    compoundId: string;
                    ingredientName: string;
                    filters: ExecutionFilters;
                    areEffectsEnabled: boolean;
                }) =>
                    this.compoundReportService
                        .getOverview(compoundId, ingredientName, filters, areEffectsEnabled)
                        .pipe(
                            map(({ overview, total }: { overview: Overview; total: number }) =>
                                actions.getOverviewSuccess({ overview, total }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getOverviewFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getRelationshipGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getRelationshipGroupsRequest),
            switchMap(
                ({
                    compoundId,
                    ingredientName,
                    filters,
                }: {
                    compoundId: string;
                    ingredientName: string;
                    filters: ExecutionFilters;
                }) =>
                    this.compoundReportService
                        .getRelationshipGroups(compoundId, ingredientName, filters)
                        .pipe(
                            map((relationshipGroups: RelationshipGroups) =>
                                actions.getRelationshipGroupsSuccess({ relationshipGroups }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getRelationshipGroupsFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getRelationshipGroupSummaries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getRelationshipGroupSummariesRequest),
            mergeMap(
                ({
                    compoundId,
                    ingredientName,
                    relationshipGroup,
                    filters,
                }: {
                    compoundId: string;
                    ingredientName: string;
                    relationshipGroup: RelationshipGroup;
                    filters: ExecutionFilters;
                }) =>
                    this.compoundReportService
                        .getRelationshipGroupSummaries(
                            compoundId,
                            ingredientName,
                            relationshipGroup,
                            filters,
                        )
                        .pipe(
                            map((relationshipGroupSummaries: RelationshipGroupSummaries) =>
                                actions.getRelationshipGroupSummariesSuccess({
                                    relationshipGroupSummaries,
                                }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getRelationshipGroupSummariesFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getEffectSummaries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getEffectSummariesRequest),
            mergeMap(
                ({
                    compoundId,
                    ingredientName,
                    effect,
                    filters,
                }: {
                    compoundId: string;
                    ingredientName: string;
                    effect: Effect;
                    filters: ExecutionFilters;
                }) =>
                    this.compoundReportService
                        .getEffectSummaries(compoundId, ingredientName, effect, filters)
                        .pipe(
                            map((effectSummaries: EffectSummaries) =>
                                actions.getEffectSummariesSuccess({ effectSummaries }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getEffectSummariesFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getHealthLabels$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getHealthLabelsRequest),
            switchMap(
                ({
                    compoundId,
                    ingredientName,
                    filters,
                    areEffectsEnabled,
                }: {
                    compoundId: string;
                    ingredientName: string;
                    filters: ExecutionFilters;
                    areEffectsEnabled: boolean;
                }) =>
                    this.compoundReportService
                        .getHealthLabels(compoundId, ingredientName, filters, areEffectsEnabled)
                        .pipe(
                            map((healthLabels: HealthLabel[]) =>
                                actions.getHealthLabelsSuccess({ healthLabels }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getHealthLabelsFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getHealthLabelSummary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getHealthLabelSummaryRequest),
            mergeMap(
                ({
                    compoundId,
                    ingredientName,
                    healthLabel,
                    effect,
                    filters,
                }: {
                    compoundId: string;
                    ingredientName: string;
                    healthLabel: string;
                    effect: Effect;
                    filters: ExecutionFilters;
                }) =>
                    this.compoundReportService
                        .getHealthLabelSummary(
                            compoundId,
                            ingredientName,
                            healthLabel,
                            effect,
                            filters,
                        )
                        .pipe(
                            map((summary: Summary) =>
                                actions.getHealthLabelSummarySuccess({ summary }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getHealthLabelSummaryFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getStudyTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getStudyTypesRequest),
            switchMap(
                ({
                    compoundId,
                    ingredientName,
                    filters,
                }: {
                    compoundId: string;
                    ingredientName: string;
                    filters: ExecutionFilters;
                }) =>
                    this.compoundReportService
                        .getStudyTypes(compoundId, ingredientName, filters)
                        .pipe(
                            map((studyTypes: StudyTypes) =>
                                actions.getStudyTypesSuccess({ studyTypes }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getStudyTypesFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getStudyType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getStudyTypeRequest),
            mergeMap(
                ({
                    compoundId,
                    ingredientName,
                    name,
                    filters,
                }: {
                    compoundId: string;
                    ingredientName: string;
                    name: string;
                    filters: ExecutionFilters;
                }) =>
                    this.compoundReportService
                        .getStudyType(compoundId, ingredientName, name, filters)
                        .pipe(
                            map((studyType: StudyType) =>
                                actions.getStudyTypeSuccess({ studyType }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getStudyTypeFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );
}
