import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class HttpParamsService {
    constructor() {}

    /**
     * It is used for avoid sending undefined (optional) parameters through HTTP requests
     * it gets a set of parameters and checks whether it's value exists or not.
     * If it exists, it appends it to object to be returned.
     * If it does not exist, it skips it (does not return it).
     */
    createHttpParams(params: Record<string, string> = {}): HttpParams {
        let httpParams: HttpParams = new HttpParams();
        Object.keys(params).forEach((param: string) => {
            if (params[param]) {
                httpParams = httpParams.set(param, params[param]);
            }
        });

        return httpParams;
    }
}
