/** third-party imports */
import { createSelector } from '@ngrx/store';

/** custom imports */
import { DataState } from '../data-state.interface';
import { CowMilkProfilerState } from './cow-milk-profiler-state.interface';
import { getDataState } from '../index';
import * as fromCowMilkProfiler from './cow-milk-profiler.reducer';
import Insight from '../ingredient-profiler/interfaces/insight.interface';
import EnhancedInsights from '../ingredient-profiler/interfaces/enhanced-insights';

export const getCowMilkProfilerState = createSelector(
    getDataState,
    (state: DataState) => state.cowMilkProfiler,
);

export const getErrors = createSelector(getCowMilkProfilerState, fromCowMilkProfiler.getErrors);
export const getCompoundsTotal = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getCompoundsTotal,
);
export const getCompoundsDisplaying = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getCompoundsDisplaying,
);
export const getCompoundsLoading = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getCompoundsLoading,
);
export const getCompoundsLoaded = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getCompoundsLoaded,
);
export const getParentCompoundId = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getParentCompoundId,
);
export const getInsights = createSelector(getCowMilkProfilerState, fromCowMilkProfiler.getInsights);
export const getInsightsTotal = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getInsightsTotal,
);
export const getInsightsDisplaying = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getInsightsDisplaying,
);
export const getInsightsPageIndex = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getInsightsPageIndex,
);
export const getInsightsLoading = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getInsightsLoading,
);
export const getInsightsLoaded = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getInsightsLoaded,
);
export const getCowMilkInsightsTotal = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getCowMilkInsightsTotal,
);
export const getCowMilkInsightsLoading = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getCowMilkInsightsLoading,
);
export const getCowMilkInsightsLoaded = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getCowMilkInsightsLoaded,
);
export const getTargetInsights = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getTargetInsights,
);
export const getTargetInsightsTotal = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getTargetInsightsTotal,
);
export const getTargetInsightsLoading = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getTargetInsightsLoading,
);
export const getTargetInsightsLoaded = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getTargetInsightsLoaded,
);
export const getOldestOccurrence = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getOldestOccurrence,
);
export const getNewestOccurrence = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getNewestOccurrence,
);
export const getMinCowMilkConcentration = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getMinCowMilkConcentration,
);
export const getMaxCowMilkConcentration = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getMaxCowMilkConcentration,
);
export const getPreferences = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getPreferences,
);
export const getMinMoleculeWeight = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getMinMoleculeWeight,
);
export const getMaxMoleculeWeight = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getMaxMoleculeWeight,
);
export const getBlob = createSelector(getCowMilkProfilerState, fromCowMilkProfiler.getBlob);
export const getOverview = createSelector(getCowMilkProfilerState, fromCowMilkProfiler.getOverview);
export const getOverviewLoading = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getOverviewLoading,
);
export const getOverviewLoaded = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getOverviewLoaded,
);
export const getHealthLabelsStatistics = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getHealthLabelsStatistics,
);
export const getHealthLabelsStatisticsLoading = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getHealthLabelsStatisticsLoading,
);
export const getHealthLabelsStatisticsLoaded = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getHealthLabelsStatisticsLoaded,
);
export const getRelationshipsPerGroup = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getRelationshipsPerGroup,
);
export const getHealthLabelSummaries = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getHealthLabelSummaries,
);
export const getHealthLabelTopCompounds = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getHealthLabelTopCompounds,
);
export const getSearchSuggestions = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getSearchSuggestions,
);
export const getSearchSuggestionsLoading = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getSearchSuggestionsLoading,
);
export const getSearchSuggestionsLoaded = createSelector(
    getCowMilkProfilerState,
    fromCowMilkProfiler.getSearchSuggestionsLoaded,
);

export const getEnhancedInsights: (state: CowMilkProfilerState) => EnhancedInsights = (
    state: CowMilkProfilerState,
) => {
    const parentCompoundId: string = getParentCompoundId(state);
    const insights: Insight[] = getInsights(state);
    const loading: boolean = getInsightsLoading(state);
    const loaded: boolean = getInsightsLoaded(state);
    const total: number = getInsightsTotal(state);
    const displaying: number = getInsightsDisplaying(state);

    return {
        parentCompoundId,
        insights,
        loading,
        loaded,
        total,
        displaying,
    };
};
