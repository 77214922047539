import { NgModule } from '@angular/core';
import { ContextMenuDirective } from './context-menu.directive';
import { DomChangesObserverDirective } from './dom-changes-observer.directive';
import { ResizeObserverDirective } from './resize-observer.directive';
import { IntersectionObserverDirective } from './intersection-observer.directive';
import { InputAutoFocusDirective } from './input-auto-focus.directive';
import { NgxDatatableResizeWatcherDirective } from './ngx-datatable-resize-watcher.directive';
import { FitInViewportHeightDirective } from './fit-in-viewport-height.directive';
import { ScientificNotationDirective } from './scientific-notation.directive';
import { BackToTopDirective } from './back-to-top.directive';
import { InteractiveTextDirective } from './interactive-text.directive';
import { EventInhibitorDirective } from './event-inhibitor.directive';

@NgModule({
    declarations: [
        NgxDatatableResizeWatcherDirective,
        InputAutoFocusDirective,
        ContextMenuDirective,
        DomChangesObserverDirective,
        ResizeObserverDirective,
        IntersectionObserverDirective,
        FitInViewportHeightDirective,
        ScientificNotationDirective,
        BackToTopDirective,
        InteractiveTextDirective,
        EventInhibitorDirective,
    ],
    imports: [],
    exports: [
        NgxDatatableResizeWatcherDirective,
        InputAutoFocusDirective,
        ContextMenuDirective,
        DomChangesObserverDirective,
        ResizeObserverDirective,
        IntersectionObserverDirective,
        FitInViewportHeightDirective,
        ScientificNotationDirective,
        BackToTopDirective,
        InteractiveTextDirective,
        EventInhibitorDirective,
    ],
})
export class DirectivesModule {}
