import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from '.';

/** layout */
import { LayoutStoreModule } from './layout/layout-store.module';

/** alerts */
import { AlertsStoreModule } from './alerts/alerts-store.module';

/** discovery */
import { DiscoveryStoreModule } from './discovery/discovery-store.module';

/** projects */
import { ProjectsStoreModule } from './projects/projects-store.module';

/** profiler */
import { ProfilerStoreModule } from './profiler/profiler-store.module';

/** ingredient profiler */
import { IngredientProfilerStoreModule } from './ingredient-profiler/ingredient-profiler-store.module';

@NgModule({
    imports: [
        CommonModule,
        LayoutStoreModule,
        AlertsStoreModule,
        DiscoveryStoreModule,
        ProjectsStoreModule,
        ProfilerStoreModule,
        IngredientProfilerStoreModule,
        StoreModule.forRoot(reducers, {}),
        StoreDevtoolsModule.instrument(),
    ],
    declarations: [],
})
export class UIStoreModule {}
