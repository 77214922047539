enum FileStatus {
    EMPTY = '',
    STAGED = 'staged',
    UPLOADING = 'uploading',
    INITIALIZING = 'initializing',
    SUCCESS = 'success',
    ERROR = 'error',
    RETRY = 'retry',
}

export default FileStatus;
