import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'snakecase' })
export class SnakeCasePipe implements PipeTransform {
    constructor() {}

    public transform(value: string): string {
        if (!value) {
            return;
        }
        return value.replace(/\s\s+/g, ' ').replace(/\s/g, '_');
    }
}
