/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as reportActions from './compound-report.actions';
import { CompoundReportState } from './compound-report-state.interface';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import Overview from './interfaces/overview.interface';
import RelationshipGroups from './interfaces/relationship-groups.interface';
import HealthLabel from './interfaces/health-label.interface';
import StudyTypes from './interfaces/study-types.interface';
import StudyType from './interfaces/study-type.interface';
import RelationshipGroupSummaries from './interfaces/relationship-group-summaries.interface';
import Summary from '../report/interfaces/summary.interface';
import EffectSummaries from './interfaces/effect-summaries.interface';

export const initialState: CompoundReportState = {
    errors: [],
    overview: null,
    overviewLoading: false,
    overviewLoaded: false,
    relationshipGroups: null,
    relationshipGroupsLoading: false,
    relationshipGroupsLoaded: false,
    relationshipGroupSummaries: null,
    effectSummaries: null,
    healthLabels: null,
    healthLabelsLoading: false,
    healthLabelsLoaded: false,
    healthLabelSummary: null,
    studyTypes: null,
    studyTypesLoading: false,
    studyTypesLoaded: false,
    studyType: null,
    total: null,
};

const compoundReportReducer: ActionReducer<CompoundReportState, Action> = createReducer(
    initialState,
    on(reportActions.getOverviewRequest, (state: CompoundReportState) => ({
        ...state,
        overviewLoading: true,
        overviewLoaded: false,
        total: null as number,
    })),
    on(
        reportActions.getOverviewSuccess,
        (
            state: CompoundReportState,
            { overview, total }: { overview: Overview; total: number },
        ) => ({
            ...state,
            overview,
            overviewLoading: false,
            overviewLoaded: true,
            total,
        }),
    ),
    on(
        reportActions.getOverviewFailure,
        (state: CompoundReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            overviewLoading: false,
            overviewLoaded: false,
        }),
    ),
    on(reportActions.getRelationshipGroupsRequest, (state: CompoundReportState) => ({
        ...state,
        relationshipGroupsLoading: true,
        relationshipGroupsLoaded: false,
    })),
    on(
        reportActions.getRelationshipGroupsSuccess,
        (
            state: CompoundReportState,
            { relationshipGroups }: { relationshipGroups: RelationshipGroups },
        ) => ({
            ...state,
            relationshipGroups,
            relationshipGroupsLoading: false,
            relationshipGroupsLoaded: true,
        }),
    ),
    on(
        reportActions.getRelationshipGroupsFailure,
        (state: CompoundReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            relationshipGroupsLoading: false,
            relationshipGroupsLoaded: false,
        }),
    ),
    on(reportActions.getRelationshipGroupSummariesRequest, (state: CompoundReportState) => ({
        ...state,
    })),
    on(
        reportActions.getRelationshipGroupSummariesSuccess,
        (
            state: CompoundReportState,
            {
                relationshipGroupSummaries,
            }: { relationshipGroupSummaries: RelationshipGroupSummaries },
        ) => ({
            ...state,
            relationshipGroupSummaries,
        }),
    ),
    on(
        reportActions.getRelationshipGroupSummariesFailure,
        (state: CompoundReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(reportActions.getEffectSummariesRequest, (state: CompoundReportState) => ({
        ...state,
    })),
    on(
        reportActions.getEffectSummariesSuccess,
        (
            state: CompoundReportState,
            { effectSummaries }: { effectSummaries: EffectSummaries },
        ) => ({
            ...state,
            effectSummaries,
        }),
    ),
    on(
        reportActions.getEffectSummariesFailure,
        (state: CompoundReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(reportActions.getHealthLabelsRequest, (state: CompoundReportState) => ({
        ...state,
        healthLabelsLoading: true,
        healthLabelsLoaded: false,
    })),
    on(
        reportActions.getHealthLabelsSuccess,
        (state: CompoundReportState, { healthLabels }: { healthLabels: HealthLabel[] }) => ({
            ...state,
            healthLabels,
            healthLabelsLoading: false,
            healthLabelsLoaded: true,
        }),
    ),
    on(
        reportActions.getHealthLabelsFailure,
        (state: CompoundReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            healthLabelsLoading: false,
            healthLabelsLoaded: false,
        }),
    ),
    on(reportActions.getHealthLabelSummaryRequest, (state: CompoundReportState) => ({
        ...state,
    })),
    on(
        reportActions.getHealthLabelSummarySuccess,
        (state: CompoundReportState, { summary }: { summary: Summary }) => ({
            ...state,
            healthLabelSummary: summary,
        }),
    ),
    on(
        reportActions.getHealthLabelSummaryFailure,
        (state: CompoundReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(reportActions.getStudyTypesRequest, (state: CompoundReportState) => ({
        ...state,
        studyTypesLoading: true,
        studyTypesLoaded: false,
    })),
    on(
        reportActions.getStudyTypesSuccess,
        (state: CompoundReportState, { studyTypes }: { studyTypes: StudyTypes }) => ({
            ...state,
            studyTypes,
            studyTypesLoading: false,
            studyTypesLoaded: true,
        }),
    ),
    on(
        reportActions.getStudyTypesFailure,
        (state: CompoundReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            studyTypesLoading: false,
            studyTypesLoaded: false,
        }),
    ),
    on(reportActions.getStudyTypeRequest, (state: CompoundReportState) => ({
        ...state,
    })),
    on(
        reportActions.getStudyTypeSuccess,
        (state: CompoundReportState, { studyType }: { studyType: StudyType }) => ({
            ...state,
            studyType,
        }),
    ),
    on(
        reportActions.getStudyTypeFailure,
        (state: CompoundReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(reportActions.resetReport, () => initialState),
    on(reportActions.clearNextError, (state: CompoundReportState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (
    state: CompoundReportState | undefined,
    action: Action,
): CompoundReportState => compoundReportReducer(state, action);

// selectors
export const getErrors: (state: CompoundReportState) => ErrorResponse[] = (
    state: CompoundReportState,
) => state.errors;
export const getOverview: (state: CompoundReportState) => Overview = (state: CompoundReportState) =>
    state.overview;
export const getOverviewLoading: (state: CompoundReportState) => boolean = (
    state: CompoundReportState,
) => state.overviewLoading;
export const getOverviewLoaded: (state: CompoundReportState) => boolean = (
    state: CompoundReportState,
) => state.overviewLoaded;
export const getRelationshipGroups: (state: CompoundReportState) => RelationshipGroups = (
    state: CompoundReportState,
) => state.relationshipGroups;
export const getRelationshipGroupsLoading: (state: CompoundReportState) => boolean = (
    state: CompoundReportState,
) => state.relationshipGroupsLoading;
export const getRelationshipGroupsLoaded: (state: CompoundReportState) => boolean = (
    state: CompoundReportState,
) => state.relationshipGroupsLoaded;
export const getRelationshipGroupSummaries: (
    state: CompoundReportState,
) => RelationshipGroupSummaries = (state: CompoundReportState) => state.relationshipGroupSummaries;
export const getEffectSummaries: (state: CompoundReportState) => EffectSummaries = (
    state: CompoundReportState,
) => state.effectSummaries;
export const getHealthLabels: (state: CompoundReportState) => HealthLabel[] = (
    state: CompoundReportState,
) => state.healthLabels;
export const getHealthLabelsLoading: (state: CompoundReportState) => boolean = (
    state: CompoundReportState,
) => state.healthLabelsLoading;
export const getHealthLabelsLoaded: (state: CompoundReportState) => boolean = (
    state: CompoundReportState,
) => state.healthLabelsLoaded;
export const getHealthLabelSummary: (state: CompoundReportState) => Summary = (
    state: CompoundReportState,
) => state.healthLabelSummary;
export const getStudyTypes: (state: CompoundReportState) => StudyTypes = (
    state: CompoundReportState,
) => state.studyTypes;
export const getStudyTypesLoading: (state: CompoundReportState) => boolean = (
    state: CompoundReportState,
) => state.studyTypesLoading;
export const getStudyTypesLoaded: (state: CompoundReportState) => boolean = (
    state: CompoundReportState,
) => state.studyTypesLoaded;
export const getStudyType: (state: CompoundReportState) => StudyType = (
    state: CompoundReportState,
) => state.studyType;
export const getTotal: (state: CompoundReportState) => number = (state: CompoundReportState) =>
    state.total;
