/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import { LayoutState } from './layout-state.interface';
import { updateFooterCopyrightDisplay, updateTermsOfUseAcceptanceStatus } from './layout.actions';
import { getFooterCopyrightDisplay, getTermsOfUseAcceptanceStatus } from './layout.selectors';

@Injectable()
export class LayoutFacade {
    isFooterCopyrightDisplayed$: Observable<boolean> = this.store.pipe(
        select(getFooterCopyrightDisplay),
    );

    isTermsOfUseAcceptanceEnabled$: Observable<boolean> = this.store.pipe(
        select(getTermsOfUseAcceptanceStatus),
    );

    constructor(private store: Store<LayoutState>) {}

    updateFooterCopyrightDisplay(isDisplayed: boolean): void {
        this.store.dispatch(updateFooterCopyrightDisplay({ isDisplayed }));
    }

    updateTermsOfUseAcceptanceStatus(isEnabled: boolean): void {
        this.store.dispatch(updateTermsOfUseAcceptanceStatus({ isEnabled }));
    }
}
