/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { SuggestionsParser } from '../parsers/suggestions.parser';
import { HttpParamEncoder } from '@leap-common/services/http-param-encoder.service';

/** Interfaces */
import ResultsRestApi from '@leap-common/rest-api-interfaces/results.rest.interface';
import SuggestionRestApi from '../rest-api-interfaces/suggestion.rest.interface';
import Suggestion from '../interfaces/suggestion.interface';

@Injectable()
export class SuggestionsService {
    constructor(private http: HttpClient, private suggestionsParser: SuggestionsParser) {}

    getSuggestions(query: string, limit: number): Observable<Suggestion[]> {
        const noOfSuggestions: string = limit.toString();

        let params: HttpParams = new HttpParams({ encoder: new HttpParamEncoder() });
        params = params.append('query', query);
        params = params.append('noOfSuggestions', noOfSuggestions);

        return this.http
            .get(`${environment.autocompleteServerUrl}/autocomplete`, {
                params,
            })
            .pipe(
                map((suggestions: ResultsRestApi<SuggestionRestApi>) =>
                    this.suggestionsParser.parseSuggestions(suggestions.results),
                ),
            );
    }
}
