import { Injectable } from '@angular/core';
import { uniq } from 'lodash';
import { isFirstOccurrence } from '@leap-common/utilities/helpers';

/** Services - Parsers */
import { InsightParser } from '@apps/leap/src/app/shared/parsers/insight.parser';
import { MetadataParser } from '../../../metadata/parsers/metadata.parser';
import { InsightsService } from '@apps/leap/src/app/shared/services/insights.service';
import { ArrayHandlerService } from '@leap-common/services/array-handler.service';

/** Interfaces - Types - Enums */
import Discovery from '@apps/leap/src/app/shared/enums/discovery.enum';
import PaginatedInsightsRestApi from '@apps/leap/src/app/shared/rest-api-interfaces/paginated-insights.rest.interface';
import PaginatedInsights from '../interfaces/paginated-insights.interface';
import InsightRestApi from '../rest-api-interfaces/insight.rest.interface';
import Insight from '../interfaces/insight.interface';
import InsightCategoriesRestApi from '@apps/leap/src/app/shared/rest-api-types/insight-categories.rest.type';
import InsightsPerCategoryRestApi from '../rest-api-types/insights-per-category.rest.type';
import InsightRelationshipType from '@apps/leap/src/app/shared/types/discovery-insight-relationship-type.type';
import SortingOrder from '@leap-common/enums/sorting-order.enum';
import AssociationOrigin from '@apps/leap/src/app/shared/enums/association-origin.enum';
import InsightsPerCategory from '../types/insights-per-category.type';

@Injectable()
export class ClosedDiscoveryParser {
    constructor(
        private insightParser: InsightParser,
        private metadataParser: MetadataParser,
        private insightsService: InsightsService,
        private arrayHandlerService: ArrayHandlerService,
    ) {}

    /** Parses Paginated Insights */
    parsePaginatedResults(
        paginatedInsights: PaginatedInsightsRestApi<InsightRestApi, InsightsPerCategoryRestApi>,
        studyTypesOrder: string[],
    ): PaginatedInsights {
        const totalCategories: InsightCategoriesRestApi[] = paginatedInsights?.results?.map(
            ({ intermediateCategories }: InsightRestApi) => intermediateCategories,
        );

        return {
            results: paginatedInsights.results
                ? this.parseInsights(paginatedInsights.results, studyTypesOrder)
                : [],
            filterCounts: this.insightParser.parseFilterCounts(paginatedInsights.counts),
            displayingInsights: paginatedInsights.totalDisplayingInsights,
            total: paginatedInsights.total,
            oldestOccurrence: paginatedInsights.totalOldestPublicationDate,
            newestOccurrence: paginatedInsights.totalNewestPublicationDate,
            minCowMilkConcentration: paginatedInsights.smallestCowMilkConcentration,
            maxCowMilkConcentration: paginatedInsights.largestCowMilkConcentration,
            minSourceConcentration: paginatedInsights.smallestSourceConcentration,
            maxSourceConcentration: paginatedInsights.largestSourceConcentration,
            minTargetConcentration: paginatedInsights.smallestTargetConcentration,
            maxTargetConcentration: paginatedInsights.largestTargetConcentration,
            minMoleculeWeight: paginatedInsights.smallestMolecularWeight,
            maxMoleculeWeight: paginatedInsights.largestMolecularWeight,
            shouldShowSourceConcentration: this.metadataParser.parseShouldShowConcentration(
                paginatedInsights.concentrationCuis,
                paginatedInsights?.results?.[0]?.sourceNodeUid,
            ),
            shouldShowTargetConcentration: this.metadataParser.parseShouldShowConcentration(
                paginatedInsights.concentrationCuis,
                paginatedInsights?.results?.[0]?.targetNodeUid,
            ),
            pageIndex: paginatedInsights.pageIndex,
            pageSize: paginatedInsights.pageSize,
            insightsPerCategory: this.parseInsightsPerCategory(
                paginatedInsights.totalInsightsPerCategory,
            ),
            groupedCategories: this.insightsService.getGroupedCategories(totalCategories),
            preferences: paginatedInsights.preferences,
        };
    }

    /** Parses BE Insights to integrate them on the FE */
    parseInsights(insights: InsightRestApi[], studyTypesOrder: string[]): Insight[] {
        return insights.map((insight: InsightRestApi) =>
            this.parseInsight(insight, studyTypesOrder),
        );
    }

    /** Parses InsightRestApi to Insight */
    parseInsight(insight: InsightRestApi, studyTypesOrder: string[]): Insight {
        const associationOriginsE0: AssociationOrigin[] =
            this.insightParser.parseAssociationOrigins(insight.associationOriginsE0);
        const associationOriginsE1: AssociationOrigin[] =
            this.insightParser.parseAssociationOrigins(insight.associationOriginsE1);
        const associationIds: string[] = [
            ...associationOriginsE0.map((associationOrigin: AssociationOrigin) =>
                this.insightsService.generateAssociationId(associationOrigin, insight.isNovelE0),
            ),
            ...associationOriginsE1.map((associationOrigin: AssociationOrigin) =>
                this.insightsService.generateAssociationId(associationOrigin, insight.isNovelE1),
            ),
        ].filter(isFirstOccurrence);
        const relationshipTypeE0: InsightRelationshipType =
            this.insightParser.parseRelationshipType(insight.sourceRelationshipType);
        const relationshipTypeE1: InsightRelationshipType =
            this.insightParser.parseRelationshipType(insight.targetRelationshipType);
        const isRelationshipTypeE0Predicted: boolean = insight.isRelationshipTypeE0Predicted;
        const isRelationshipTypeE1Predicted: boolean = insight.isRelationshipTypeE1Predicted;
        const sortedSourceCategories: InsightCategoriesRestApi =
            this.arrayHandlerService.sortObjectByValues(insight.sourceCategories);
        const sortedIntermediateCategories: InsightCategoriesRestApi =
            this.arrayHandlerService.sortObjectByValues(insight.intermediateCategories);
        const sortedTargetCategories: InsightCategoriesRestApi =
            this.arrayHandlerService.sortObjectByValues(insight.targetCategories);
        const koOriginDatabasesE0: string[] = this.insightParser.parseKOOriginDatabases(
            insight.koSourceDBE0,
        );
        const koOriginDatabasesE1: string[] = this.insightParser.parseKOOriginDatabases(
            insight.koSourceDBE1,
        );
        const koOriginDatabases: string[] = [...koOriginDatabasesE0, ...koOriginDatabasesE1]
            .filter(isFirstOccurrence)
            .sort((databaseA: string, databaseB: string) =>
                this.arrayHandlerService.sort(databaseA, databaseB, {
                    direction: SortingOrder.ascending,
                }),
            );
        const studyTypesE0: string[] = insight.typesOfStudyE0 || [];
        const studyTypesE1: string[] = insight.typesOfStudyE1 || [];
        const studyTypes: string[] = [...studyTypesE0, ...studyTypesE1]
            .filter(isFirstOccurrence)
            .sort(this.arrayHandlerService.getSortOrder(studyTypesOrder, undefined, undefined));
        const journalsE0: string[] = this.insightParser.parseJournals(insight.journalsE0);
        const journalsE1: string[] = this.insightParser.parseJournals(insight.journalsE1);
        const journals: string[] = [...journalsE0, ...journalsE1]
            .filter(isFirstOccurrence)
            .sort((journalA: string, journalB: string) =>
                this.arrayHandlerService.sort(journalA, journalB, {
                    direction: SortingOrder.ascending,
                }),
            );

        return {
            type: Discovery.closed,
            id: `${insight.sourceNodeUid}_${insight.intermediateNodeUid}_${insight.targetNodeUid}`,
            sourceCategories: sortedSourceCategories || {},
            sourceId: insight.sourceNodeUid,
            sourceName: insight.sourceNodeName,
            sourcePrior: insight.sourceNodePrior,
            intermediateCategories: sortedIntermediateCategories || {},
            intermediateId: insight.intermediateNodeUid,
            intermediateName: insight.intermediateNodeName,
            intermediatePrior: insight.intermediateNodePrior,
            targetCategories: sortedTargetCategories || {},
            targetId: insight.targetNodeUid,
            targetName: insight.targetNodeName,
            targetPrior: insight.targetNodePrior,
            weightPpmiE0: insight.weightPpmiE0,
            weightPpmiE1: insight.weightPpmiE1,
            weightPpmiSum: insight.weightPpmiSum,
            weightRawE0: insight.weightRawE0,
            weightRawE1: insight.weightRawE1,
            weightRawSum: insight.weightRawSum,
            weightScoreE0: this.insightParser.parseScore(insight.weightScoreE0),
            weightScoreE1: this.insightParser.parseScore(insight.weightScoreE1),
            weightScoreSum: this.insightParser.parseScore(insight.weightScoreSum),
            isNovelE0: insight.isNovelE0,
            isNovelE1: insight.isNovelE1,
            relationshipTypeE0,
            relationshipTypeE1,
            relationshipTypeValues: [
                ...this.insightParser.parseRelationshipTypeValues(relationshipTypeE0),
                ...this.insightParser.parseRelationshipTypeValues(relationshipTypeE1),
            ].filter(isFirstOccurrence),
            isRelationshipTypeE0Predicted,
            isRelationshipTypeE1Predicted,
            areRelationshipTypesPredicted: [
                isRelationshipTypeE0Predicted,
                isRelationshipTypeE1Predicted,
            ].filter(isFirstOccurrence),
            predictedRelationshipTypeProbabilityE0: this.insightParser.parseProbability(
                insight.relationshipTypeProbabilityE0,
            ),
            predictedRelationshipTypeProbabilityE1: this.insightParser.parseProbability(
                insight.relationshipTypeProbabilityE1,
            ),
            linkProbabilityE0: insight.linkProbabilityE0,
            linkProbabilityE1: insight.linkProbabilityE1,
            intermediateArticlesCount: this.insightParser.parseArticlesCount(insight.noOfArticles),
            patentsCount: insight.noOfPatents,
            oldestOccurrenceE0: this.insightParser.parseDate(insight.oldestPublicationDateE0),
            newestOccurrenceE0: this.insightParser.parseDate(insight.newestPublicationDateE0),
            oldestOccurrenceE1: this.insightParser.parseDate(insight.oldestPublicationDateE1),
            newestOccurrenceE1: this.insightParser.parseDate(insight.newestPublicationDateE1),
            rankingIndex: insight.index,
            sortIntermediateName: insight.intermediateNodeName.toLowerCase(),
            synonyms: this.insightParser.parseSynonyms(insight.synonyms),
            koOriginDatabasesE0,
            koOriginDatabasesE1,
            koOriginDatabases,
            associationOriginsE0,
            associationOriginsE1,
            associationIds,
            sourceTags: this.insightParser.parseTags(insight.sourceTags?.Tags),
            intermediateTags: this.insightParser.parseTags(insight.intermediateTags?.Tags),
            targetTags: this.insightParser.parseTags(insight.targetTags?.Tags),
            sourceHealthLabels: this.insightParser.parseHealthLabels(
                insight.sourceTags?.['Health areas'],
            ),
            intermediateHealthLabels: this.insightParser.parseHealthLabels(
                insight.intermediateTags?.['Health areas'],
            ),
            targetHealthLabels: this.insightParser.parseHealthLabels(
                insight.targetTags?.['Health areas'],
            ),
            studyTypesE0,
            studyTypesE1,
            studyTypes,
            journalsE0,
            journalsE1,
            journals,
            sourceMolecules: this.insightParser.parseMolecules(
                insight.sourceTags?.['Molecule classification'],
            ),
            intermediateMolecules: this.insightParser.parseMolecules(
                insight.intermediateTags?.['Molecule classification'],
            ),
            targetMolecules: this.insightParser.parseMolecules(
                insight.targetTags?.['Molecule classification'],
            ),
            sourceLabs: this.insightParser.parseLabs(insight.sourceTags?.['UCD DMD lab']),
            intermediateLabs: this.insightParser.parseLabs(
                insight.intermediateTags?.['UCD DMD lab'],
            ),
            targetLabs: this.insightParser.parseLabs(insight.targetTags?.['UCD DMD lab']),
            subcategories: insight.intermediateCategories
                ? Object.keys(insight.intermediateCategories).sort(
                      (subcategoryA: string, subcategoryB: string) =>
                          this.arrayHandlerService.sort(subcategoryA, subcategoryB, {
                              direction: SortingOrder.ascending,
                          }),
                  )
                : [],
            categories: sortedIntermediateCategories
                ? uniq(Object.values(sortedIntermediateCategories))
                : [],
            moleculeWeight: insight.molecularWeight,
            cowMilkConcentration: insight.cowMilkConcentration,
            articlesCountE0: insight.noOfCommonArticlesE0,
            articlesCountE1: insight.noOfCommonArticlesE1,
            proteinOrigins: this.insightParser.parseProteinOrigins(insight.sourceProtein),
            concentrationE0: this.metadataParser.parseConcentrationMeasurement(
                insight?.concentrations?.[insight.sourceNodeUid],
            ),
            concentrationE1: this.metadataParser.parseConcentrationMeasurement(
                insight?.concentrations?.[insight.targetNodeUid],
            ),
        };
    }

    parseInsightsPerCategory(insightsPerCategory: InsightsPerCategoryRestApi): InsightsPerCategory {
        return Object.entries(insightsPerCategory).reduce(
            (
                parsedInsightsPerCategory: InsightsPerCategory,
                [category, categoryInsights]: [string, Record<'total' | 'novel', number>],
            ) => {
                parsedInsightsPerCategory[category] = {
                    novel: categoryInsights?.novel || 0,
                    total: categoryInsights?.total || 0,
                };
                return parsedInsightsPerCategory;
            },
            {},
        );
    }

    serializeInsight(insight: Insight): InsightRestApi {
        return {
            sourceCategories: insight.sourceCategories,
            sourceNodeUid: insight.sourceId,
            sourceNodeName: insight.sourceName,
            sourceNodePrior: insight.sourcePrior,
            intermediateCategories: insight.intermediateCategories,
            intermediateNodeUid: insight.intermediateId,
            intermediateNodeName: insight.intermediateName,
            intermediateNodePrior: insight.intermediatePrior,
            targetCategories: insight.targetCategories,
            targetNodeUid: insight.targetId,
            targetNodeName: insight.targetName,
            targetNodePrior: insight.targetPrior,
            weightPpmiE0: insight.weightPpmiE0,
            weightPpmiE1: insight.weightPpmiE1,
            weightPpmiSum: insight.weightPpmiSum,
            weightRawE0: insight.weightRawE0,
            weightRawE1: insight.weightRawE1,
            weightRawSum: insight.weightRawSum,
            weightScoreE0: insight.weightScoreE0,
            weightScoreE1: insight.weightScoreE1,
            weightScoreSum: insight.weightScoreSum,
            isNovelE0: insight.isNovelE0,
            isNovelE1: insight.isNovelE1,
            sourceRelationshipType: this.insightParser.serializeRelationshipType(
                insight.relationshipTypeE0,
            ),
            targetRelationshipType: this.insightParser.serializeRelationshipType(
                insight.relationshipTypeE1,
            ),
            isRelationshipTypeE0Predicted: insight.isRelationshipTypeE0Predicted,
            isRelationshipTypeE1Predicted: insight.isRelationshipTypeE1Predicted,
            relationshipTypeProbabilityE0: insight.predictedRelationshipTypeProbabilityE0,
            relationshipTypeProbabilityE1: insight.predictedRelationshipTypeProbabilityE1,
            linkProbabilityE0: insight.linkProbabilityE0,
            linkProbabilityE1: insight.linkProbabilityE1,
            noOfArticles: this.insightParser.serializeArticlesCount(
                insight.intermediateArticlesCount,
            ),
            noOfPatents: insight.patentsCount,
            oldestPublicationDateE0: this.insightParser.serializeDate(insight.oldestOccurrenceE0),
            newestPublicationDateE0: this.insightParser.serializeDate(insight.newestOccurrenceE0),
            oldestPublicationDateE1: this.insightParser.serializeDate(insight.oldestOccurrenceE1),
            newestPublicationDateE1: this.insightParser.serializeDate(insight.newestOccurrenceE1),
            index: insight.rankingIndex,
            synonyms: this.insightParser.serializeSynonyms(insight.synonyms),
            koSourceDBE0: this.insightParser.serializeKOOriginDatabases(
                insight.koOriginDatabasesE0,
            ),
            koSourceDBE1: this.insightParser.serializeKOOriginDatabases(
                insight.koOriginDatabasesE1,
            ),
            associationOriginsE0: this.insightParser.serializeAssociationOrigins(
                insight.associationOriginsE0,
            ),
            associationOriginsE1: this.insightParser.serializeAssociationOrigins(
                insight.associationOriginsE1,
            ),
            sourceTags: this.insightParser.serializeTags(
                insight.sourceTags,
                insight.sourceHealthLabels,
                insight.sourceLabs,
                insight.sourceMolecules,
            ),
            intermediateTags: this.insightParser.serializeTags(
                insight.intermediateTags,
                insight.intermediateHealthLabels,
                insight.intermediateLabs,
                insight.intermediateMolecules,
            ),
            targetTags: this.insightParser.serializeTags(
                insight.targetTags,
                insight.targetHealthLabels,
                insight.targetLabs,
                insight.targetMolecules,
            ),
            typesOfStudyE0: insight.studyTypesE0,
            typesOfStudyE1: insight.studyTypesE1,
            journalsE0: insight.journalsE0,
            journalsE1: insight.journalsE1,
            molecularWeight: insight.moleculeWeight,
            cowMilkConcentration: insight.cowMilkConcentration,
            noOfCommonArticlesE0: insight.articlesCountE0,
            noOfCommonArticlesE1: insight.articlesCountE1,
            sourceProtein: this.insightParser.serializeProteinOrigins(insight.proteinOrigins),
            concentrations: this.metadataParser.serializeConcentrationMeasurements(
                [insight.sourceId, insight.concentrationE0],
                [insight.targetId, insight.concentrationE1],
            ),
        };
    }
}
