import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';
import { DebouncedFunc, throttle } from 'lodash';

@Directive({
    selector: '[libBackToTop]',
})
export class BackToTopDirective implements AfterViewInit, OnDestroy {
    @Input() scrollToTopSelector: string;
    @Output() scrolled: EventEmitter<boolean> = new EventEmitter();

    constructor(private element: ElementRef) {}

    ngAfterViewInit(): void {
        this.element.nativeElement.addEventListener('scroll', this.throttledScrollListener);
    }

    ngOnDestroy(): void {
        this.throttledScrollListener.cancel();
        this.element.nativeElement.removeEventListener('scroll', this.throttledScrollListener);
    }

    /**
     *  The reason for using the arrow function instead of the traditional one is that the 'this'
     *  keyword is generally bound to various values depending on the situation in which it is invoked.
     *  The 'this' keyword is lexically bound with arrow functions, so it utilizes 'this' from the code
     *  that includes the arrow function.
     */
    scrollWatcherCallback = (): void => {
        const heightPercentage: number = 20 / 100;

        const isScrolled: boolean =
            this.element.nativeElement.scrollTop >
            this.element.nativeElement.scrollHeight * heightPercentage;

        this.scrolled.emit(isScrolled);

        if (isScrolled) {
            this.element.nativeElement
                .querySelector(this.scrollToTopSelector)
                ?.addEventListener('click', this.onBackToTopClicked);
        }
    };

    // Throttle scroll listener calls for better performance
    throttledScrollListener: DebouncedFunc<() => void> = throttle(this.scrollWatcherCallback, 40);

    onBackToTopClicked = (): void => {
        this.element.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
    };
}
